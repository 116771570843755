import axios from "../../client/axios";

export const getCustomerLocations = () => axios.get('/customer-locations');

export const selectCustomerLocation = (locationId) => axios.put(`/customer-locations/${locationId}`, {
  is_selected: true,
});

export const getCustomerPhoneNumber = (email) => axios.get('/customer/phone_number', { params: { email } });

export const saveCustomerPhoneNumber = (phoneNumber) => axios.put(`/customer/phone_number`, { phoneNumber })

export const saveAddress = async ({ name, address_field1, address_field2, city, state_province, postal_code }) => {
  try {
    const geocode = await axios.post('customer-locations/geocode', {
      address: `${address_field1} ${address_field2} ${city}, ${state_province} ${postal_code}`
    });
    if (!geocode.err && geocode.data.data) {
      const geocodeAddress = geocode.data.data.address;
      const addressToSave = {
        ...geocode.data.data.position,
        address_field1: address_field1,
        address_field2: geocodeAddress.street,
        postal_code,
        is_selected: true,
        country: geocodeAddress.countryName,
        state_province: geocodeAddress.state,
        city: geocodeAddress.city,
        name,
      };
      await axios.post('/customer-locations', addressToSave);
      return Promise.resolve(addressToSave);
    }
  } catch (error) {
      return Promise.reject(error);
  }
};