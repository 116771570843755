import React from "react";
import {
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonMenu,
	IonMenuToggle,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";
import { logout } from "../../client-submodule/redux/auth/auth.actions";

export const routes = {
	appPages: [
		{ title: "Menu", path: "/menu" },
		{ title: "Catering", path: "/catering" },
		{ title: "Our Story", path: "/our-story" },
		{ title: "Safety", path: "/covid-19" },
		{ title: "Locations", path: "/locations" },
		{ title: "Cart", path: "/cart" },
	],
	loggedInPages: [],
	loggedOutPages: [{ title: "Log In", path: "/login" }],
};

const Sidebar = ({ user, logoutUser }) => {
	// const location = useLocation();
	const renderlistItems = ({ path, title }) => {
		if ((title === 'Catering' && process.env.REACT_APP_ENABLE_CATERING === 'true') || title !== 'Catering') {
			return <IonMenuToggle key={path} auto-hide="false">
				<Link className="text-link" to={path}>
					<IonItem button style={{ textTransform: "none" }}>
						<IonLabel className="u-padding-vertical-sm">{title}</IonLabel>
					</IonItem>
				</Link>
			</IonMenuToggle>
		}
	};

	const navLinks = routes.appPages;

	const isLoggedIn = Boolean(user);

	return (
		<IonMenu content-id="main-content" side="end" className="c-sidebar" style={{ top: 70, "--background": "transparent" }}>
			<IonHeader>
				<IonToolbar className="nav-header" style={{ '--background': 'var(--app-nav-header-background)' }}>
					<IonTitle className="color-purple ion-no-padding ion-text-left">
						{isLoggedIn
							? <IonLabel className="ion-padding-horizontal">{`Hi, ${user.name}`}</IonLabel>
							: (
								<IonMenuToggle auto-hide="false" className="u-full-width d-inline-block">
									<Link className="text-link d-inline-block u-full-width" to="/login">
										<IonLabel className="ion-padding-horizontal">Log In</IonLabel>
									</Link>
								</IonMenuToggle>
							)}
					</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonList>
					{navLinks.map(route => renderlistItems(route))}
					{isLoggedIn && (
						<>
							{renderlistItems({ path: "/manage-orders", title: "Orders" })}
							<IonMenuToggle auto-hide="false">
								<IonItem onClick={() => logoutUser()} button>
									<IonLabel>SignOut</IonLabel>
								</IonItem>
							</IonMenuToggle>
						</>)}
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

const mapDispatchToProps = {
	logoutUser: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

