const CartActionTypes = {
    FETCH_CART_START: "FETCH_CART_START",
    FETCH_CART_SUCCESS: "FETCH_CART_SUCCESS",
    FETCH_CART_FAILURE: "FETCH_CART_FAILURE",
    ADD_TO_CART_START: 'ADD_TO_CART_START',
    ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
    ADD_TO_CART_FAILURE: 'ADD_TO_CART_FAILURE',
    FETCH_CATERING_CART_SUCCESS: "FETCH_CATERING_CART_SUCCESS",
}

export default CartActionTypes; 