import React from "react";
import {
	IonPage,
	IonContent,
} from "@ionic/react";
import Navbar from "../../components/Navbar";
import SubscribeBanner from "./SubscribeBanner";
import "./index.scss";

const Subscribe = () => {
	return (
		<IonPage>
			<Navbar />
			<IonContent>
				<SubscribeBanner />
			</IonContent>
		</IonPage>
	);
};

export default Subscribe;
