import CartActionTypes from "./cart.types";
import { cartServices } from "./cart.services";

export const fetchCartStart = () => ({
	type: CartActionTypes.FETCH_CART_START,
});

export const fetchCartSuccess = (businessInfo) => ({
	type: CartActionTypes.FETCH_CART_SUCCESS,
	payload: businessInfo,
});

export const fetchCateringCartSuccess = (businessInfo) => ({
	type: CartActionTypes.FETCH_CATERING_CART_SUCCESS,
	payload: businessInfo,
});

export const fetchCartError = (error) => ({
	type: CartActionTypes.FETCH_CART_FAILURE,
	payload: error,
});

const fetchCartItemsByRestaurantId = restaurantId =>
	new Promise((resolve, reject) => {
		cartServices.getCartByUser(restaurantId)
		.then(data => resolve(data.data),
			(err) => {
				const status = err?.response?.status;
				if (status === 404) { // empty cart
					return resolve([]);
				}
				return reject(err);
			});
	})

export const fetchCartItems = () => {
	return (dispatch, getState) => {
		dispatch(fetchCartStart());
		const restaurantId = getState().business.restaurantId;
		return fetchCartItemsByRestaurantId(restaurantId)
		.then(data => {
				dispatch(fetchCartSuccess(data.cart_items));
				return data;
			}
		)
		.catch(error => dispatch(fetchCartError(error.message)))
	};
};

export const updateCartItems = (restaurantId) => {
	return (dispatch) => {
		return cartServices.getCartByUser(restaurantId).then(
			(data) => {
				dispatch(fetchCartSuccess(data.data));
			},
			(error) => {
				dispatch(fetchCartError(error.message));
			}
		);
	};
};

export const addToCartStart = () => ({
	type: CartActionTypes.ADD_TO_CART_START,
});

export const addToCartSuccess = () => ({
	type: CartActionTypes.ADD_TO_CART_SUCCESS,
});

export const addToCartError = (error) => ({
	type: CartActionTypes.ADD_TO_CART_FAILURE,
	payload: error,
});

/**
 * Adds item to cart
 * @param {Number} restaurantId Restaurant id -> To refactor. Get the id from store instead of passing (Make sure it has quantity field and id)
 * @param {Object} menuItem Menu item from response cart response
 * @returns {Promise} Returns promise if logged in else undefined
 */
export const addItemToCart = (restaurantId, menuItem) => async (dispatch, getState) => {
	dispatch(addToCartStart());
	const cartItems = getState().cart.cartItems;
	const itemIndex = cartItems.findIndex(c => c.menu_item_id === menuItem.menu_item_id);
	let updatedCart = cartItems.slice();	
	if (itemIndex !== -1) {
		updatedCart.splice(itemIndex, 1, menuItem);
	} else {
		updatedCart.push(menuItem);
	}
	updatedCart = updatedCart.filter(c => c.quantity > 0);
	dispatch(fetchCartSuccess(updatedCart));
	if (getState().auth.user) {
		return cartServices.addToCart(
			restaurantId,
			[{ menu_item_id: menuItem.menu_item_id || menuItem.id, quantity: menuItem.quantity }],
			).then(
			(data) => {
				dispatch(addToCartSuccess());
			},
			(error) => {
				dispatch(addToCartError(error.message));
			}
			);
	} else {
		dispatch(addToCartSuccess());
	}
};

export const addToCart = item => (dispatch, getState) => {
	const restaurantId = getState().business.restaurantId;
	dispatch(addItemToCart(restaurantId, item));
};

export const syncCart = () => async (dispatch, getState) => {
	dispatch(fetchCartStart());
	const restaurantId = getState().business.restaurantId;
	try {
		const cartResponse = await fetchCartItemsByRestaurantId(restaurantId);
		const remoteCartItems = cartResponse.cart_items;
		const localCartItems = getState().cart.cartItems;
		const remoteItemIds = remoteCartItems.map(item => item.menu_item_id);
		const unsyncedLocalItems = localCartItems.filter(item => !remoteItemIds.includes(item.menu_item_id));
		if (unsyncedLocalItems?.length) {
			const itemsToAdd = unsyncedLocalItems.map(item => ({
				menu_item_id: item.menu_item_id,
				quantity: item.quantity,
			}))
			const updatedCart = await cartServices.addToCart(restaurantId, itemsToAdd);
			dispatch(fetchCartSuccess(updatedCart.cart_items));
		} else {
			const unsyncedRemoteItems = localCartItems.filter(item => !remoteItemIds.includes(item.menu_item_id));
			dispatch(fetchCartSuccess(unsyncedRemoteItems.concat(remoteCartItems)));
		}
	} catch (error) {
		dispatch(fetchCartError(error))
	}
};

export const addItemToCateringCart = (restaurantId, menuItem) => async (dispatch, getState) => {
	dispatch(addToCartStart());
	const cartItems = getState().cart.cateringCartItems?getState().cart.cateringCartItems:[];
	const itemIndex = cartItems.findIndex(c => c.menu_item_id === menuItem.menu_item_id);
	let updatedCart = cartItems.slice();	
	if (itemIndex !== -1) {
		updatedCart.splice(itemIndex, 1, menuItem);
	} else {
		updatedCart.push(menuItem);
	}
	updatedCart = updatedCart.filter(c => c.quantity > 0);
	dispatch(fetchCateringCartSuccess(updatedCart));
	
    dispatch(addToCartSuccess());
	
};
