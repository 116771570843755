import { IonImg, IonPage } from "@ionic/react";
import React from "react";
import "@codetrix-studio/capacitor-google-auth";
import { Plugins, registerWebPlugin } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { connect } from "react-redux";
import { fbLogin, googleLogin } from "../../client-submodule/redux/auth/auth.actions";
import {
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonRow,
	IonCol,
	IonText,
	IonButton,
} from "@ionic/react";
import "./index.scss"

class Login extends React.Component {
	constructor() {
		super();
		this.setupFbLogin();
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace(this.props.location.state || '/');
		}
	}
	
	async setupFbLogin() {
		if (isPlatform("desktop")) {
			registerWebPlugin(FacebookLogin);
		}
	}
	googleSignIn = async () => {
		const { history } = this.props;
		const result = await Plugins.GoogleAuth.signIn();
		console.info("result", result);
		if (result && result.serverAuthCode) {
			await this.props.googleLogin(result.serverAuthCode);
			if (this.props.location.state) {
				history.push({
					pathname: this.props.location.state.pathname
				});
			} else {
				history.push({
					pathname: "/"
				});
			}
		}
	};

	// getCurrentState = async() => Promise {
	//     const result = await Plugins.FacebookLogin.getCurrentAccessToken();
	//     try {
	//       return result && result.accessToken;
	//     } catch (e) {
	//       return false;
	//     }
	//   }

	fbSignIn = async () => {
		const { history } = this.props;
		const FACEBOOK_PERMISSIONS = ["public_profile", "email"];
		const result = await FacebookLogin.login({
			permissions: FACEBOOK_PERMISSIONS,
		});
		console.log("fb login", result);
		if (result && result.accessToken && result.accessToken.token) {
            await this.props.fbLogin(result.accessToken.token);
			history.push({
				pathname: "/"
			});
		}
	};

	render() {
		return (
			<IonPage>
				<IonContent>
					<div className="wrapper u-flex ion-align-items-center ion-justify-content-center">
						<div className="ion-text-center">
							<IonImg
								className="u-margin-bottom_1"
								src="/images/susans-logo.png"
							/>
							<div
								className="g-signin-btn"
								onClick={() => this.googleSignIn()}
								role="button"
							>
								<img src="/images/btn_google_signin_light_normal_web@2x.png" alt="Login with Google" />
							</div>
							<div className="fb-btn">
								<div className="fb-login-button" data-width="" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
							</div>
							</div>
							</div>
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
	isLoggedIn: state.auth.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
	fbLogin: (token) => dispatch(fbLogin(token)),
	googleLogin: (code) => dispatch(googleLogin(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
