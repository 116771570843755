import axios from "../../client/axios";

export const businessServices = {
	getBusinessInfo: async (id) => {
		try {
			const response = await axios.get(`/business/${id}`);
			return response.data;
		} catch (e) {
			throw e;
		}
	},
	getBusinessSettings: async () => {
		const response = await axios.get('/settings');
		return response.data.data;
	},
	getPaymentProviders: async (id) => {
		const response = await axios.get(`/payment-providers/restaurant/${id}/public`);
		return response.data.data;
	},
	getTestimonials: async (id) => {
		const response = await axios.get('/testimonial', {
			params: {
				restaurant_id: id,
			}
		});
		return response.data.data;
	},
	fetchRestaurantById: async (id) => {
		try {
			const response = await axios.get(`/restaurants/${id}` );
			return response.data;
		} catch (e) {
			throw e;
		}
	},
};
