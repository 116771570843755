import MenuActionTypes from "./menu.types";

const INITIAL_STATE = {
    menuItems: {
        menu_items: [],
    },
    menus: [],
    isFetching : false,
    errorMessage : '',
    selectedMenuId: 1,
}

const menuReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case MenuActionTypes.FETCH_MENU_ITEMS_START: 
            return {
                ...state,
                isFetching: true
            }
        case MenuActionTypes.FETCH_MENU_ITEMS_SUCCESS:
             return {
                 ...state,
                 isFetching: false,
                 menuItems: action.payload
             }
       case MenuActionTypes.FETCH_MENU_RESTAURENT_SUCCESS:
                return {
                    ...state,
                    isFetching: false,
                    restaurentSettings: action.payload
                }
        case MenuActionTypes.FETCH_MENU_ITEMS_FAILURE: 
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        case MenuActionTypes.FETCH_MENUS:
            return {
                ...state,
                menus: action.payload,
                isFetching: false,
            }
        case MenuActionTypes.SET_SELECTED_MENU:
            return {
                ...state,
                selectedMenuId: action.payload,
            }
        default:
            return state
    }
}

export default menuReducer;