import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol } from "@ionic/react";
import React,{useState} from "react";
import { useToast } from "@agney/ir-toast";
import "./index.scss";
import { Link } from "react-router-dom";
import QuantityInput from "../QuantityInput";

const MenuItemCard = (props) => {
	const {
		addToCart,
		isAdded,
		menuItem,
		currencySymbol,
		restaurantId,
		max,
		min=1
	} = props;
	const {
		id,
		name,
		price,
		description,
		photo_url,
	} = menuItem;
	const Toast = useToast();
	const [cartCount, setCartCount] = useState(1)

	return (
		<IonCol sizeLg="3" sizeXl="3" sizeMd="4" className="menu-item-card ion-no-padding">
			<IonCard className="ion-no-margin u-card-shadow u-border catring-menus">
				<Link to={`/menu/${id}`} className="u-text-inherit" state={props}>
					<div className="item-image">
						<img src={photo_url} alt="" />
					</div>
					<IonCardContent className="item-details">
						<div className="item-name">{name}</div>
						<div className="u-font-regular color-dark price-menu">{`${currencySymbol}${price}`}</div>

					</IonCardContent>

				</Link>
				<IonCardHeader className="item-sale ion-no-padding">
				<QuantityInput
                    maxQuantity={max}
                    minQuantity={min}
                    value={cartCount}
					
                    className="item-sale-controller"
                    onChange={(quantity) =>
                      // addItemToCart(3, {
                      //   ...cartItem,
                      //   quantity,
                      // })
                      setCartCount(quantity)
                    }
                  />
					<IonButton
						onClick={(e) => {
							addToCart(restaurantId, { ...menuItem, quantity: cartCount, menu_item_id: menuItem.id });
							const toast = Toast.create({ message: `${menuItem.name} added to cart.`, duration: 2000 });
							toast.present();
						}}
						className="add-cart color-dark bg-green ion-no-margin text-transform-inherit u-height-inherit"
						fill="clear"
						// disabled={isAdded}
					>
						{/* {isAdded ? 'Added to Cart' : 'Add to Cart'} */}
						Add to Cart
					</IonButton>
				</IonCardHeader>
			</IonCard>
		</IonCol>
	);
};

export default MenuItemCard;
