import axios from "../../client/axios";

export const cartServices = {
	getCartByUser: async (restaurantId) => {
		try {
			const response = await axios.get(`/cart/restaurant/${restaurantId}`);
			return response.data;
		} catch (e) {
			throw e;
		}
	},
	addToCart: async (restaurantId, items) => {
		try {
			const response = await axios.post(`/cart/restaurant/${restaurantId}/cart-item`, items);
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
};
