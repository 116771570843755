import { IonGrid, IonRow } from "@ionic/react";
import React, { useMemo } from "react";
import { connect } from "react-redux"
import { getCurrencySymbolFromCode } from "../../client-submodule/helpers/cart";
import { addItemToCart } from "../../client-submodule/redux/cart/cart.actions";
import { cartItemMapSelector } from "../../client-submodule/redux/cart/cart.selectors";
import FavouriteMenuItem from "./index";

const MenuSuggestions = ({ menuItems, isMenuItemsLoading, cartItemMap, settings, addItemToCart, restaurantId, restaurentSettings }) => {

  const currencySymbol = useMemo(() => {
    return getCurrencySymbolFromCode(settings.find(s => s.setting_type === 'CURRENCY_CODE')?.setting_value);
  },[settings]);

  return (
    menuItems?.length > 0 && (
      <IonGrid fixed>
        <div className="popular-items-title">Indulge in these giant scrumptious samosas today!</div>
        <div className="popular-items">
          {isMenuItemsLoading ? (
            <div>Loading...</div>
          ) : (
            <IonGrid className="favourite-items ion-padding">
              <IonRow>
                {menuItems.slice(0, 3).map((item, idx) => (
                  <FavouriteMenuItem
                    key={idx}
                    menuItem={item}
                    addToCart={addItemToCart}
                    isAdded={cartItemMap.has(item.id)}
                    currencySymbol={currencySymbol}
                    restaurantId={restaurantId}
                    min={item.regular_min_quantity?item.regular_min_quantity:restaurentSettings?.min_order_quantity}
                    max={item.regular_max_quantity?item.regular_max_quantity:restaurentSettings?.max_order_quantity}
                  />
                ))}
              </IonRow>
            </IonGrid>
          )}
        </div>
      </IonGrid>
    )
  );
};

const mapStateToProps = (state) => ({
  isMenuItemsLoading: state.menu.isFetching,
  menuItems: state.menu.menuItems.menu_items,
  settings: state.business.settings,
  restaurantId: state.business.restaurantId,
  restaurentSettings: state.menu.restaurentSettings,
  ...cartItemMapSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addItemToCart: (restaurantId, menuItem) =>
    dispatch(addItemToCart(restaurantId, menuItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSuggestions);
