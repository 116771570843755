import AuthActionTypes from "./auth.types";

const INITIAL_STATE = {
    user: null,
    isFetching : true,
    errorMessage : ''
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case AuthActionTypes.LOGIN_START: 
            return {
                ...state,
                isFetching: true
            }
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                 ...state,
                 isFetching: false,
                 user: action.payload
            }
        case AuthActionTypes.LOGIN_FAILURE: 
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        case AuthActionTypes.KICK_USER_OFF:
            return INITIAL_STATE;
        default:
            return state
    }
}

export default authReducer;