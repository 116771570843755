import { IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { ReactComponent as CheckIcon } from "../../assets/icons/icon_complete.svg";
import SubscribeBanner from "../Subscribe/SubscribeBanner";
import Footer from "../../components/Footer";

const Confirmation = (props) => {
  const location = useLocation();console.log(props)
  return (
    <IonPage>
      <Navbar />
      <IonContent>
        <div className="c-padding-xl ion-text-center">
          <h5 className="ion-no-margin">THANK YOU FOR YOUR ORDER!</h5>
          <h5 className="ion-margin-start">
            WE ARE WORKING TO GET IT TO YOU AS SOON AS POSSIBLE
          </h5>
          <CheckIcon className="ion-margin checkmark" />
          <h5>ORDER CONFIRMED!</h5>
          <IonText>
            We are pleased to confrm your order #{props.match.params.orderNum}
          </IonText>
          <br />
          <IonText className="ion-margin">
            A confrmation email has been sent to your email address            
          </IonText>
          <div className="u-flex ion-justify-content-center ion-margin">
            <IonButton
              className="ion-margin"
              style={{ "--background": "var(--ion-color-tertiary)" }}
            >
              <Link to={`/manage-orders/${props.match.params.orderNum}`} className="text-link">
                TRACK YOUR ORDER {">"}
              </Link>
            </IonButton>
            <IonButton
              className="ion-margin"
              style={{ "--background": "#b6cc09" }}
            >
              <Link to="/menu" className="text-link">
                CONTINUE SHOPPING {">"}
              </Link>
            </IonButton>
          </div>
        </div>
        <SubscribeBanner />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Confirmation;
