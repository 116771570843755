import CustomerActionTypes from "./customer.types";

const INITIAL_STATE = {
  locations: [],
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CustomerActionTypes.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      }
    case CustomerActionTypes.ADD_LOCATION: 
      return {
        ...state,
        locations: state.locations.concat(action.payload),
      }
    default: return state;
  }
};

export default customerReducer;
