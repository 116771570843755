import { createSelector } from 'reselect';

const cartItemsSelector = state => state.cart.cartItems;

export const cartItemMapSelector = createSelector(
  cartItemsSelector,
  (cartItems) => {
    return {
      cartItemMap: new Map(cartItems.map(m => [m.id, m])),
    }
  }
);
