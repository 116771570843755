import BusinessActionTypes from "./business.types";
import { businessServices } from "./business.services";
import APIError from "../../helpers/api";

export const fetchBusinessStart = () => ({
	type: BusinessActionTypes.FETCH_BUSINESS_START,
});

export const fetchBusinessSuccess = (businessInfo) => ({
	type: BusinessActionTypes.FETCH_BUSINESS_SUCCESS,
	payload: businessInfo,
});

export const fetchBusinessError = (error) => ({
	type: BusinessActionTypes.FETCH_BUSINESS_FAILURE,
	payload: error,
});

export const fetchBusinessInfo = (id) => {
	return (dispatch) => {
		dispatch(fetchBusinessStart());
		return businessServices.getBusinessInfo(id).then(
			(data) => {
				dispatch(fetchBusinessSuccess(data.data));
			},
			(error) => {
				dispatch(fetchBusinessError(error.message));
			}
		);
	};
};

export const fetchTestimonials = (id) => dispatch => {
		return businessServices.getTestimonials(id)
			.then(data => {
					dispatch({
						type: BusinessActionTypes.SET_TESTIMONALS,
						payload: data.testimonials,
					});
				},
				(error) => {
					throw new APIError(error);
				}
			);
};

export const fetchSettings = () => async dispatch => {
	try {
		const { settings } = await businessServices.getBusinessSettings();
		dispatch({
			type: BusinessActionTypes.SET_BUSINESS_SETTINGS,
			payload: settings,
		})
	} catch (error) {
		throw new APIError(error);
	}
};

export const fetchPaymentProvider = (id) => async dispatch => {
	try {
		const { settings } = await businessServices.getPaymentProviders(id);
		dispatch({
			type: BusinessActionTypes.SET_BUSINESS_SETTINGS,
			payload: settings,
		})
	} catch (error) {
		throw new APIError(error);
	}
};

export const fetchRestaurantById = (id) => {
	return (dispatch) => {
		dispatch(fetchBusinessStart());
		return businessServices.fetchRestaurantById(id).then(
			(data) => {
				dispatch(fetchBusinessSuccess(data.data));
			},
			(error) => {
				dispatch(fetchBusinessError(error.message));
			}
		);
	};
};

export const setRestaurantId = id => dispatch => {
	dispatch ({
		type: BusinessActionTypes.SET_RESTAURANT_ID,
		payload: id,
	});
};
