import { combineReducers } from "redux";
import Storage from "../helpers/storage";
import authReducer from './auth/auth.reducer';
import AuthActionTypes from "./auth/auth.types";
import businessReducer from './business/business.reducer';
import cartReducer from "./cart/cart.reducer";
import customerReducer from "./customer/customer.reducer";
import menuReducer from "./menu/menu.reducer";
import orderReducer from "./order/order.reducer";

const appReducer = combineReducers({
    business: businessReducer,
    menu: menuReducer,
    auth: authReducer,
    cart: cartReducer,
    customer: customerReducer,
    order: orderReducer,
});

const rootReducer = (state, action) => {
    if (action.type ===  AuthActionTypes.LOGOUT) {
        Storage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action)
};

export default rootReducer;
