import CateringActionTypes from "./catering.types";
import { cateringRequests } from "./catering.services";
import Storage from "../../helpers/storage";
import CatringActionTypes from "./catering.types";

export const addCateringStart = () => ({
  type: CateringActionTypes.FETCHING_START,
});

export const addCateringSuccess = (postCatering) => (dispatch) => {
  dispatch({
    type: CatringActionTypes.FETCHING_SUCCESS,
    payload: postCatering,
  });
};

export const addCateringError = (error) => ({
  type: CatringActionTypes.FETCHING_FAILURE,
  payload: error,
});

export const addCatering = (cateringData) => {
  return (dispatch) => {
    dispatch(addCateringStart());
    return cateringRequests.addCateringRequests(cateringData).then(
      async (response) => {
        dispatch(addCateringSuccess(response));
      },
      (error) => {
        dispatch(addCateringError(error.message));
      }
    );
  };
};
