import React, { useState } from "react";
import {
  IonButtons,
  IonButton,
  IonToolbar,
  IonHeader,
  IonImg,
  IonThumbnail,
  IonMenuToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
  IonList,
  IonItem,
} from "@ionic/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { routes } from "../Sidebar";
import { connect } from "react-redux";
import CartIcon from "./CartIcon";
import { logout } from "../../client-submodule/redux/auth/auth.actions";



const Navbar = ({ user, cartItemCount, logoutUser }) => {
  const navLinks = routes.appPages.concat(
    user ? routes.loggedInPages : routes.loggedOutPages
  );
  const location = useLocation();
  const history = useHistory();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  Array.prototype.sum = function (prop) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop]
    }
    return total
  }


  const openPopup = (e) => {
    if (!popoverState.showPopover) {
      e.persist();
      setShowPopover({ showPopover: true, event: e });
    }
  };

  const renderNavlinks = (path, title) => {
    if (path === '/cart') {
      return (
        <Link key={path} className="text-link u-full-height" to="/cart">
          <IonButton style={{ textTransform: "none", background: location.pathname === path ? '#e1e1e1' : 'inherit' }} className="u-full-height">
            <CartIcon count={cartItemCount && cartItemCount.sum('quantity')} />
          </IonButton>
        </Link>
      );
    }
    else if ((title === 'Catering' && process.env.REACT_APP_ENABLE_CATERING === 'true') || title !== 'Catering') {
      return (
        <Link className="text-link u-full-height" key={path} to={{ pathname: path, state: location }}>
          <IonButton className="u-full-height" style={{ textTransform: "none", background: location.pathname === path ? '#e1e1e1' : 'inherit' }}>
            {title}
          </IonButton>
        </Link>
      );
    }      
  }

  return (
    <IonHeader>
      <IonToolbar
      // style={{ "--background": "#fcf5ed" }}
      // className="header"
      >
        <IonButtons
          slot="start"
          className="ion-justify-content-between u-full-width"
        >
          <IonGrid className="ion-no-padding ion-padding-horizontal">
            <IonRow>
              <IonCol
                sizeMd={4}
                sizeSm={12}
                className="u-flex ion-align-items-center ion-justify-content-between"
              >
                <Link className="text-link" to="/">
                  <IonImg
                    className="logo-image"
                    src="/images/susans-logo.png"
                  />
                </Link>
                <div className="u-flex ion-align-items-center ion-hide-md-up">
                  <Link className="text-link" to="/cart">
                    <IonButton style={{ textTransform: "none" }}>
                      <CartIcon count={cartItemCount && cartItemCount.sum('quantity')} />
                    </IonButton>
                  </Link>
                  <IonMenuToggle style={{ width: '3.4rem' }}>
                    <IonButton className="u-full-width">
                      <IonThumbnail>
                        <img src="/images/icon_menu.svg" alt="Menu" />
                      </IonThumbnail>
                    </IonButton>
                  </IonMenuToggle>
                </div>
              </IonCol>
              <IonCol
                sizeMd={8}
                className="u-flex ion-align-items-center ion-justify-content-end ion-hide-md-down ion-no-padding"
              >
                {navLinks
                  .map(({ path, title }) => renderNavlinks(path, title))}
                {user && (
                  <IonButton style={{ textTransform: "none" }}
                    onClick={openPopup}
                    onMouseEnter={openPopup}
                    className="u-full-height"
                  >
                    Hi {user.given_name || user.name}
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonButtons>
      </IonToolbar>
      <IonPopover
        showBackdrop={false}
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <IonList>
          <Link className="text-link" to="/manage-orders">
            <IonItem button onClick={() => setShowPopover({ showPopover: false, event: undefined })} className="u-font-regular">
              Manage Orders
            </IonItem>
          </Link>
          <IonItem className="u-font-regular" button onClick={() => {
            setShowPopover({ showPopover: false, event: undefined })
            logoutUser();
            history.push('/');
          }}>
            SignOut
          </IonItem>
        </IonList>
      </IonPopover>
    </IonHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cartItemCount: state.cart.cartItems,
});

const mapDispatchToProps = {
  logoutUser: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
