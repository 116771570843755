import AuthActionTypes from "./auth.types";
import { authServices } from "./auth.services";
import Storage from "../../helpers/storage";
import { syncCart } from "../cart/cart.actions";

export const loginStart = () => ({
	type: AuthActionTypes.LOGIN_START,
});

export const loginSuccess = (userInfo) => dispatch => {
	dispatch({
		type: AuthActionTypes.LOGIN_SUCCESS,
		payload: userInfo,
	});
	dispatch(syncCart())
};

export const loginError = (error) => ({
	type: AuthActionTypes.LOGIN_FAILURE,
	payload: error,
});

export const fbLogin = (accessToken) => {
	return (dispatch) => {
		dispatch(loginStart());
		return authServices.fbLogin(accessToken).then(
			async (response) => {
				await Storage.setItem("user", response);
				dispatch(loginSuccess(response));
			},
			(error) => {
				dispatch(loginError(error.message));
			}
		);
	};
};

export const googleLogin = (authCode, accessToken) => {
	return (dispatch) => {
		dispatch(loginStart());
		return authServices.googleLogin(authCode, accessToken).then(
			async (response) => {
				await Storage.setItem("user", response);
				dispatch(loginSuccess(response));
			},
			(error) => {
				dispatch(loginError(error.message));
			}
		);
	};
};

export const logout = () => ({
	type: AuthActionTypes.LOGOUT,
});

export const kickUserOff = () => ({
	type: AuthActionTypes.KICK_USER_OFF,
});
