import React, { useEffect, useState } from 'react';
import Input from '../Input';
import { saveCustomerPhoneNumber, getCustomerPhoneNumber } from '../../client-submodule/redux/customer/customer.services.js';
import { IonButton } from '@ionic/react';
import { useToast } from "@agney/ir-toast";

export default function TelephoneInput({ phoneNumber, email }) {
    const [number, setNumber] = useState('');
    const Toast = useToast();

    useEffect(() => {
        getPhoneNumber();
    }, [])

    const getPhoneNumber = async () => {
        console.log('EMAILLLL', email)
        const response = await getCustomerPhoneNumber(email);
        if(response.err) {
            const toast = Toast.create({ message: 'There was a problem getting your phone number', duration: 2000 });
            toast.present();
        }
        
        console.log('response', response)
        if(response.data.data.phone_number) {
            setNumber(response.data.data.phone_number)
        }
    }

    const updatePhoneNumber = async () => {
        if(number) {
            try {
                const response = await saveCustomerPhoneNumber(number);
                const toast = Toast.create({ message: 'Updated phone number successfully', duration: 2000 });
                toast.present();
            } catch(e) {
                const toast = Toast.create({ message: 'There was a problem updating your phone number', duration: 2000 });
                toast.present();
            }        
        }        
    }

    return <div>
        {
            phoneNumber ?
                <span>Phone Number: <b>{phoneNumber}</b></span>
                :
                <div>
                    <Input
                        showError={!number}
                        label="Phone Number"
                        type="tel"
                        autocomplete="tel"
                        value={number}
                        onChange={(val) => setNumber(val)}
                    />
                    <br />
                    <IonButton style={{ '--background': '#b6cc09' }}
                        onClick={updatePhoneNumber}>
                        Update Phone Number
                    </IonButton>
                </div>
        }
    </div>;
}
