import React from "react";
import {
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonModal,
  IonList,
  isPlatform,
} from "@ionic/react";
import { Link } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PayPalButton } from "react-paypal-button-v2";

import StripeCheckoutForm from "../../components/StripeCheckoutForm";
import "./index.scss";
import CartContent from "../Cart/CartContent";
import { getCurrencySymbolFromCode } from "../../client-submodule/helpers/cart";
import { ReactComponent as UserIcon } from "../../assets/icons/icon_people.svg";
import { ReactComponent as CartIcon } from "../../assets/icons/icon_cart.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/icon_location.svg";
import { ReactComponent as DollarIcon } from "../../assets/icons/icon_dollar.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/icon_plus_circle.svg";
import Input from "../../components/Input";
import { saveAddress } from "../../client-submodule/redux/customer/customer.services";
import FavouriteMenuItem from "../../components/FavouriteMenuItem";
import SquarePayment from "./SquarePayment";
import squareicon from "../../assets/icons/square.png"
import { connect } from 'react-redux';
import TelephoneInput from "../../components/TelephoneInput";
import { getTax, getTotal } from '../../client-submodule/helpers/cart';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class CheckoutComponent extends React.Component {
  nonPickedItems = (this.props.menuItems?.menu_items ?? []).filter(
    (m) => !this.props.cartItemMap.has(m.id)
  );
  state = {
    showSquareForm: false,
    showStripeForm: false,
    deliveryMethod: "pickup",
    showAddressForm: false,
    address: {
      name: "Home",
      address_field1: "",
      address_field2: "",
      city: "",
      state_province: "",
      postal_code: "",
    },
    customerLocations: [],
    isFormDirty: false,
  };

  payWithRazor = () => {
    return {
      currency: "USD", // your 3 letter currency code
      key: "rzp_test_GVS4xvNDjFckcK", // your Key Id from Razorpay dashboard
      amount: 100, // Payment amount in smallest denomiation e.g. cents for USD
      name: "Susans Samosas",
      modal: {
        ondismiss: function () {
          // alert("dismissed");
        },
      },
    };
  };

  payWithStripe() {
    if (isPlatform("ios") || isPlatform("android")) {
      this.props.payWithStripe();
    } else {
      this.setState({
        showStripeForm: true,
      });
    }
  }

  payWithSquare() {
    this.setState({
      showSquareForm: true,
    });

  }

  closeStripe = () => {
    this.setState({
      showStripeForm: false,
    });
  };

  successCallback = (payment_id) => {
    alert("payment_id: " + payment_id);
  };

  cancelCallback = (error) => {
    alert(error.description + " (Error " + error.code + ")");
  };

  saveAddress = async () => {
    this.setState({ isFormDirty: true });
    if (
      Object.keys(this.state.address).every((key) => key !== 'address_field2' ? this.state.address[key] : true)
    ) {
      try {
        const savedAddress = await saveAddress(
          Object.assign({}, this.state.address, { is_selected: true })
        );
        this.props.handleAddAddress(savedAddress);
        this.setState({ showAddressForm: false, isFormDirty: false });
      } catch (error) {
        console.log(error);
      }
    }
  };

  onPaypalPaymentCompletion = (data, details) => {
    this.props.onPaymentCompletion({ token: data.orderID, provider: 'PAYPAL' })
  };

  getETATime = (eta = 1) => {
    return new Date(new Date().getTime() + eta * 60 * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  renderAddNewAddressForm = () => (
    <IonModal
      isOpen={this.state.showAddressForm}
      style={{ "--max-width": "unset", "--min-height": "unset" }}
      cssClass="address-modal"
    >
      <header className="c-modal-header">
        <h1 className="color-purple">Add New Address</h1>
      </header>
      <div className="c-modal-content ion-padding">
        <IonList>
          <Input
            showError={this.state.isFormDirty && !this.state.address.name}
            label="Label"
            autocomplete="given-name"
            value={this.state.address.name}
            onChange={(val) =>
              this.setState({
                address: Object.assign(this.state.address, { name: val }),
              })
            }
          />
          <Input
            showError={this.state.isFormDirty && !this.state.address.address_field1}
            label="Address Line 1"
            autocomplete="address-line1"
            value={this.state.address.address_field1}
            onChange={(val) =>
              this.setState({
                address: Object.assign({}, this.state.address, {
                  address_field1: val,
                }),
              })
            }
          />
          <Input
            // showError={this.state.isFormDirty && !this.state.address.address_field2}
            label="Address Line 2"
            autocomplete="address-line2"
            value={this.state.address.address_field2}
            onChange={(val) =>
              this.setState({
                address: Object.assign({}, this.state.address, {
                  address_field2: val,
                }),
              })
            }
          />
          <Input
            showError={this.state.isFormDirty && !this.state.address.city}
            label="City"
            autocomplete="address-line3"
            value={this.state.address.city}
            onChange={(val) =>
              this.setState({
                address: Object.assign(this.state.address, { city: val }),
              })
            }
          />
          <Input
            showError={this.state.isFormDirty && !this.state.address.state_province}
            label="State"
            value={this.state.address.state_province}
            onChange={(val) =>
              this.setState({
                address: Object.assign(this.state.address, {
                  state_province: val,
                }),
              })
            }
          />
          <Input
            showError={this.state.isFormDirty && !this.state.address.postal_code}
            label="Postal Code"
            autocomplete="postal-code"
            value={this.state.address.postal_code}
            onChange={(val) =>
              this.setState({
                address: Object.assign(this.state.address, {
                  postal_code: val,
                }),
              })
            }
          />
        </IonList>
      </div>
      <div className="ion-margin ion-text-center">
        <IonButton
          onClick={() => this.setState({ showAddressForm: false })}
          color="medium"
          className="u-mr__1"
        >
          Cancel
        </IonButton>
        <IonButton
          style={{ "--background": "#b6cc09", "--color": "#000" }}
          onClick={this.saveAddress}
        >
          Save
        </IonButton>
      </div>
    </IonModal>
  );

  renderPaymentPartners = () => {
    const tax = getTax(this.props?.restaurentSettings?.tax_percent, this.props.billTotal);
    const total = getTotal(tax, this.props.billTotal);

    const paymentProviderSettings =
      this.props?.restaurentSettings?.payment_provider ? this.props?.restaurentSettings?.payment_provider.provider : ""      
    const paymentProviders = paymentProviderSettings.split(",");    
    return (
      <>
        <p>Payment Processing Partner</p>
        <div className="u-flex ion-wrap">
          {paymentProviders.includes("PAYPAL") && (
            <PayPalButton
              amount={total}
              shippingPreference="NO_SHIPPING"
              options={{
                clientId: this.props?.restaurentSettings?.payment_provider.client_id,
              }}
              onSuccess={(details, data) =>
                this.onPaypalPaymentCompletion(data, details)
              }
              onCancel={(data) => { }}
              onError={(error) => console.error(error)}
            />
          )}
          {paymentProviders.includes("STRIPE") && (
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => this.payWithStripe()}
            >
              <IonImg className="payment-button" src={"../images/stripe.svg"} />
            </div>
          )}
          {paymentProviders.includes("SQUARE") && (
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => this.payWithSquare()}
            >
              <IonImg className="payment-button" src={squareicon} />
            </div>
          )}
          {(!isPlatform("ios") || !isPlatform("android")) &&
            paymentProviders.includes("RAZORPAY") && (
              <div
                style={{ cursor: "pointer" }}
                className="ion-margin-horizontal"
                role="button"
                onClick={() =>
                  window.Razorpay.open(
                    this.payWithRazor(),
                    this.successCallback,
                    this.cancelCallback
                  )
                }
              >
                <IonImg
                  className="payment-button"
                  src="./images/Razorpay.png"
                />
              </div>
            )}
        </div>
      </>
    );
  };

  render() {
    const menuSuggestions = this.nonPickedItems.slice(0, 2);
    const currencySymbol = getCurrencySymbolFromCode(
      this.props.settings.find((s) => s.setting_type === "CURRENCY_CODE")
        ?.setting_value
    );
      console.log(this.props.user);
    const tax = getTax(this.props?.restaurentSettings?.tax_percent, this.props.billTotal);
    const total = getTotal(tax, this.props.billTotal);
    const selectedLocation = this.props.customerLocations.find(location => location.is_selected);
    const finalLocation = selectedLocation || this.props.customerLocations[0];
    return (
      <IonContent style={{ "--background": "#fcf5ed" }}>
        <IonGrid fixed>
          <p className="ion-padding-horizontal ion-margin-top">
            Review address, payments, and tip to complete your purchase
          </p>
          <IonRow>
            <IonCol sizeMd={7}>
              <div className="order-options">
                <div className="option-item account-detail">
                  <UserIcon className="order-icon" />
                  {this.props.user ? (
                    <>
                      <h2 className="account-logged-in">Logged In </h2>
                      <p style={{ marginTop: "20px" }}>
                        {this.props.user?.name}
                      </p>
                      <TelephoneInput email={this.props.user?.email} />
                    </>
                  ) : (
                    <div>
                      <h2 className="account-logged-in">Have an account?</h2>
                      <br />
                      <Link
                        to={{
                          pathname: "/login",
                          state: this.props.location,
                        }}
                      >
                        Login to place the Order
                      </Link>
                    </div>
                  )}
                </div>

                <div className="option-item">
                  <CartIcon className="order-icon" />
                  <IonSegment
                    value={this.state.deliveryMethod}
                    onIonChange={(e) =>
                      this.setState({ deliveryMethod: e.detail.value })
                    }
                  >
                    <IonSegmentButton
                      value="pickup"
                      style={{
                        "--background-checked": "#b6cc09",
                        "--color": "#000",
                        "--color-checked": "#000",
                      }}
                    >
                      <IonLabel>Pickup</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="delivery"
                      style={{
                        "--background-checked": "#b6cc09",
                        "--color": "#000",
                        "--color-checked": "#000",
                      }}
                    >
                      <IonLabel>Delivery</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                  <div style={{ marginTop: '20px' }}>
                    <span style={{ color: 'grey' }}>If you place the order now</span>
                    {
                      this.state.deliveryMethod === 'pickup' ?
                        <h4 style={{ color: 'red' }}>
                          {"Your order will be ready for pickup by "}&nbsp;{this.getETATime(this.props.restaurentSettings &&
                            this.props.restaurentSettings.eta_pickup_order)}
                        </h4>
                        :
                        <h4 style={{ color: 'red' }}>
                          {"Your order will be delivered around "}&nbsp;{this.getETATime(this.props.restaurentSettings &&
                            this.props.restaurentSettings.eta_delivery_order)}&nbsp;{" to the following address"}
                        </h4>
                    }
                  </div>
                </div>
                {
                    Boolean(this.props.user) && <IonGrid className="option-item">
                      <LocationIcon className="order-icon" />
                      { this.state.deliveryMethod === "delivery" ? <h2 className="box-heading">Your delivery address</h2>:<h2 className="box-heading">Your billing address</h2>}
                      <p>For current and future orders</p>
                      <IonRow>
                        {this.props.customerLocations.map(location => (
                          <IonCol sizeSm={6} role="button" key={location.id} className="address" onClick={() => {this.props.selectLocation(location.id)}}>
                            <div className={`${location.is_selected ? 'selected' : 'address-item'} ion-padding`}>
                              <h6 className="ion-no-margin ion-margin-bottom">{location.name}</h6>
                              <h6 className="ion-no-margin">{location.address_field1}</h6>
                              <h6 className="ion-no-margin">{location.address_field2}</h6>
                              <h6 className="ion-no-margin">{location.city}</h6>
                              <h6 className="ion-no-margin">{location.state_province}</h6>
                              <h6 className="ion-no-margin">{location.country}</h6>
                              <h6 className="ion-no-margin">{location.postal_code}</h6>
                            </div>
                          </IonCol>
                        ))}
                        <IonCol>
                          <div className="u-flex ion-align-items-center ion-justify-content-center c-dashed-border">
                            <IonButton
                              fill="outline"
                              color="success"
                              onClick={() =>
                                this.setState({ showAddressForm: true })
                              }
                              disabled={!this.props.user}
                            >
                              ADD NEW
                            </IonButton>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                }
                <div className="option-item">
                  <AddIcon className="order-icon" />
                  <h2 className="box-heading">Great Accompaniments</h2>
                  <h5>Add these to make a perfect meal</h5>
                  <IonRow>
                    {menuSuggestions.map((item, idx) => (
                      <FavouriteMenuItem
                        key={idx}
                        isAdded={this.props.cartItemMap.has(item.id)}
                        colProps={{ sizeMd: 7, sizeSm: 12 }}
                        menuItem={item}
                        currencySymbol={currencySymbol}
                        addToCart={this.props.addItemToCart}
                        restaurantId={this.props.restaurantId}
                        min={0}
                        max={this.props.restaurentSettings && this.props.restaurentSettings.max_order_quantity}
                      />
                    ))}
                  </IonRow>
                </div>
                <div className="option-item">
                  <DollarIcon className="order-icon" />
                  <h2 className="box-heading">Add Payment Details</h2>
                  <p>Amount to pay: ${total}</p>
                  {this.props.user ? (
                    this.renderPaymentPartners()
                  ) : (
                    <p>
                      Please <Link to={{ pathname: '/login', state: this.props.location }}>Login</Link> to place order
                    </p>
                  )}

                  {this.state.showStripeForm && (
                    <Elements stripe={stripePromise}>
                      <StripeCheckoutForm closeStripe={this.closeStripe} onPaymentCompletion={this.props.onPaymentCompletion} />
                    </Elements>
                  )}
                  {this.state.showSquareForm && (
                    <div>
                      <div className="square-title">
                        <div>Square Card</div>
                        <IonImg
                          className="close-img"
                          src="/images/close.png"
                          onClick={() => {
                            this.setState({
                              showSquareForm: false
                            })
                          }}
                        />
                      </div>
                      <SquarePayment client_id={this.props?.restaurentSettings?.payment_provider.client_id} amount={total} onPaymentCompletion={this.props.onPaymentCompletion} customerLocation={finalLocation} customer={this.props.user}/>
                    </div>
                  )

                  }
                </div>
              </div>
            </IonCol>
            <IonCol sizeMd={5}>
              <div className="cart-details">
                <CartContent fromOrders />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {this.renderAddNewAddressForm()}
      </IonContent>
    );
  }
}

const mapStateToProps = ({ menu }) => ({
  restaurentSettings: menu.restaurentSettings,

});

export default connect(mapStateToProps)(CheckoutComponent);
