import React from "react";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";

const SubscribeBanner = () => {
  const { handleSubmit, control } = useForm();
  const onSubmit = (data) => {
    alert(JSON.stringify(data, null, 2));
  };
  return (
    <div className="subscribe-page ion-padding-vertical">
      <IonGrid fixed>
        <IonRow className="ion-align-items-center">
          <IonCol className="subscribe-text">
            <h2>
              Subscribe <br />
              to our
              <br /> newsletter!
            </h2>
            <div className="subscribe-caption">
              Subscribe to our newsletter to recieve hot updates on offers and
              samosas and much more! Be the VIP at the susan’s samosas
            </div>
          </IonCol>
          <IonCol className="subscribe-form">
            <form
              className="subscribe-form-body"
              onSubmit={handleSubmit((data) => console.log(data))}
            >
              <IonItem className="form-item">
                <IonLabel position="floating">Name</IonLabel>
                <Controller
                  render={({ onChange, onBlur, value }) => (
                    <IonInput onIonChange={onChange} />
                  )}
                  control={control}
                  name="name"
                />
              </IonItem>
              <IonItem className="form-item">
                <IonLabel position="floating">Email</IonLabel>
                <Controller
                  render={({ onChange, onBlur, value }) => (
                    <IonInput onIonChange={onChange} />
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  }}
                />
              </IonItem>

              <IonButton style={{ '--background': 'var(--ion-color-light)'}} className="color-dark" type="submit">submit</IonButton>
            </form>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SubscribeBanner;
