import { IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import React from "react";
import MenuSuggestions from "../../components/FavouriteMenuItem/MenuSuggestions";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import "./index.scss";

class About extends React.PureComponent {
  render() {
    return (
      <IonPage>
        <Navbar />
        <IonContent className="about-page">
          <div className="about-banner u-flex ion-align-items-center ion-justify-content-between">
            <div className="about-banner-left">
              <img src="./images/banner-bg-2.svg" alt="" />
            </div>
            <h1>THE 'DIAMOND'
            <br /> OF SAMOSAS
						</h1>
            <div className="about-banner-right">
              <img src="./images/banner-bg-2.svg" alt="" />
            </div>
          </div>
          <div className="about-description">
            <IonGrid fixed className="ion-padding">
              <IonRow>
                <div className="about-description-title">
                  Meet{" "}
                  <span className="description-title-highlight ">Susan</span>
                </div>
                <div className="about-description-content">
                  <div className="about-description-text">
                    <p>
                      Susan’s journey is synonymous with overcoming great
                      adversity through a lens of unparalleled positivity. At
                      six, Susan found refuge at a Kenyan orphanage escaping a
                      decades long civil war in Sudan. At 18, she journeyed to
                      the US as one of the lost girls,ﬁnding her way to ,
                      Colorado.
                    </p>
                    <p>
                      Faced with an entirely new culture, new language, and even
                      snow, Susan was determined to make the most out of life.
                      Through hardwork and dedication, Susan graduated from
                      college and began a career in early childhood education.
                      Then, Covid-19 struck.
                    </p>
                    <p>
                      Once again, facing adversity, Susan lost her job and with
                      it, the means to support her son and herself.
                    </p>
                    <p>
                      Susan did what she always does -- since she was a little
                      girl, she persevered! Susan made lemonade out of lemons,
                      or in her case, delectable samosas. With love and
                      positivity, Susan has sold more than 10,000 samosas
                      locally.
                    </p>
                  </div>
                  <div className="about-description-image ion-hide-sm-down">
                    <img src="./images/about-image.png" alt="" />
                  </div>
                </div>
              </IonRow>
						</IonGrid>
            <MenuSuggestions />
          </div>
					<Footer />
        </IonContent>
      </IonPage>
    );
  }
};

export default About;
