import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonDatetime,
  IonFooter,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import { heart } from "ionicons/icons"

const instagramUrl = "https://www.instagram.com/susansamosas";
const fbUrl = "https://www.facebook.com/susansamosas";

const links = [
  { label: "Home", link: "/" },
  { label: "Menu", link: "/menu" },
  { label: "Catering", link: "/catering" },  
  { label: "Our Story", link: "/our-story" },
  { label: "COVID-19", link: "/covid-19" },
];

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [queryDate, setQueryDate] = useState();
  return (
    <IonFooter className={styles.footer}>
      <IonGrid fixed className="ion-align-items-center">
        <IonRow>
          <IonCol sizeSm={4} sizeMd={2}>
            {links.map(({ label, link }) => {
              if((label === 'Catering' && process.env.REACT_APP_ENABLE_CATERING === 'true') || label !== 'Catering') {
                return <h6 className={styles.link} key={link}>
                <Link to={link} className="text-link">
                  {label}
                </Link>
              </h6>
              }
            })}
            <IonText><h6>Follow us</h6></IonText>
            <h6 className="u-flex">
              <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                <img
                  src="/images/icon_ig.png"
                  alt="instagram"
                  className={styles.socialIcon}
                />
              </a>
              <a href={fbUrl} target="_blank" rel="noopener noreferrer">
                <img
                  src="/images/icon_fb.png"
                  alt="facebook"
                  className={styles.socialIcon}
                />
              </a>
            </h6>
          </IonCol>
          <IonCol sizeSm={8} sizeMd={6}></IonCol>
          {/* <IonCol sizeSm={8} sizeMd={6}>
            <IonTitle color="dark" className="u-margin-bottom_1">HAVE A CATERING ORDER?</IonTitle>
            <IonItem style={{ '--background': 'transparent' }}>
              <IonLabel position="floating">Enter Name</IonLabel>
              <IonInput type="text" autocomplete="name" value={name} onIonChange={e => setName(e.detail.value)} />
            </IonItem>
            <IonItem style={{ '--background': 'transparent' }}>
              <IonLabel position="floating">Enter Email Address</IonLabel>
              <IonInput type="email" autocomplete="email" value={email} onIonChange={e => setEmail(e.detail.value)} />
            </IonItem>
            <IonItem style={{ '--background': 'transparent' }}>
              <IonLabel>Query Date</IonLabel>
              <IonDatetime
                displayFormat="DD-MMMM"
                value={queryDate} onIonChange={e => setQueryDate(e.detail.value)}
              />
            </IonItem>
            <div className="u-flex u-margin-top_1">
            <IonButton className={`${styles.button} bg-green`} style={{ '--background': '#b6cc09' }}>Submit</IonButton>
            <IonButton className={`${styles.button} bg-green`} style={{ '--background': '#b6cc09' }}>See Menu</IonButton>
            </div>
          </IonCol> */}
          <IonCol sizeMd={3} pushSm={1} className="u-flex ion-hide-md-down">
            <IonImg src="./images/susans-logo.png" />
          </IonCol>
        </IonRow>
        <br/><br/>
        <IonRow>
          <IonCol style={{ textAlign: 'center' }}>
            <small>
              Made with <IonIcon className="heartbeat" style={{ color: 'red' }} icon={heart} /> by <a target="_blank" rel="noreferrer noopener" href="https://www.r2c.io">R2 Consulting</a>
            </small>
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <small>
              Powered by <a target="_blank" rel="noreferrer noopener" href="https://www.foodluv.io">Foodluv</a>
            </small>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>
  );
};

export default Footer;
