import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonThumbnail,
} from "@ionic/react";
import React from "react";
import Navbar from "../../components/Navbar";
import styles from "./styles.module.scss";
import Footer from "../../components/Footer";
import MenuSuggestions from "../../components/FavouriteMenuItem/MenuSuggestions";

const smPoints = [
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">We always</h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          {" "}
          wear masks
        </h1>
      </div>
    ),
  },
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">
          We wash hands often <br /> vigorously for at least{" "}
        </h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          20 secs with soap and hot water
        </h1>
      </div>
    ),
  },
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">We always</h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          wear gloves.
        </h1>
      </div>
    ),
  },
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">We offer</h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          contactless delivery <br /> and takeout options.
        </h1>
      </div>
    ),
  },
];

const CovidTab = () => {
  return (
    <IonPage>
      <Navbar />
      <IonContent className="bg-white">
        <div className={`${styles.banner} business-cover`}>
          <IonGrid fixed className="ion-no-padding">
            <IonRow>
              <IonCol className="u-flex ion-align-items-center">
                <div>
                  <IonText className={styles.preTitle}>Serving</IonText>
                  <div className={styles.bannerTitle}>
                    <div className="u-flex ion-align-items-center">
                    <h1 className="ion-no-margin title-xl text1 u-pos-rel">
                      <span className="c-hyphen u-pos-rel">Safe</span>
                    </h1>
                    <div className="u-flex u-ml__2">
                      {Array(3)
                        .fill(true)
                        .map((_, i) => (
                          <IonThumbnail key={i} className={styles.samIcon}>
                            <IonImg src="/images/samosa.svg" alt="Samosa" />
                          </IonThumbnail>
                        ))}
                    </div>
                    </div>
                    <h1 className={`ion-no-margin text2 u-pos-rel ${styles.line2}`}>Samosas</h1>
                  </div>
                </div>
                
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-horizontal">
              {smPoints.map((p, i) => (
                <IonCol
                  key={i}
                  sizeXs={12}
                  sizeSm={i === 0 || i === 3 ? 12 : 6}
                  className="u-flex ion-align-items-center ion-justify-content-end ion-margin-vertical"
                >
                  <div className="u-flex ion-align-items-center ion-justify-content-end ion-margin-vertical">
                    <IonImg
                      src={`/images/icon_covidtab_${i + 1}.png`}
                      className={styles.pointCount}
                    />
                    {p.content}
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid fixed>
          <IonRow className="ion-align-items-center">
            <IonCol sizeSm={9} sizeXs={12}>
              <div className="ion-text-left">
                <p className={`u-font-semi-bold ${styles.para}`}>
                  We are constantly monitoring reputable sources like the &nbsp;
                  <a href="https://www.cdc.gov/" target="_blank" rel="noopener noreferrer" className="u-link">CDC</a>
                  &nbsp; and <a href="https://www.who.int/" target="_blank" rel="noopener noreferrer" className="u-link">WHO</a> and update our protocols accordingly to ensure our
                  customer’s safety when preparing and packaging food.
                </p>
              </div>
            </IonCol>
            <IonCol sizeMd={1} className="ion-hide-sm-down" />
            <IonCol sizeMd={2} sizeSm={3} className="ion-hide-sm-down">
              <IonImg src="/images/covid_page_icon1.png" />
            </IonCol>
            <MenuSuggestions />
          </IonRow>
        </IonGrid>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default CovidTab;
