import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonTitle,
  IonButton,
} from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { startCase } from "lodash";
import { format } from "date-fns";
import { orderServices } from "../../client-submodule/redux/order/order.services";
import MenuSuggestions from "../../components/FavouriteMenuItem/MenuSuggestions";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Table from "../../components/Table";
import { getCurrencySymbolFromCode } from "../../client-submodule/helpers/cart";
// import "./index.scss";
import { Link } from "react-router-dom";

const columns = [
  {
    name: "Date",
    selector: "created_at",
    sortable: true,
    cell: (row) => <div>{format(new Date(row.created_at), "dd-mm-yyyy")}</div>,
  },
  {
    name: "Address",
    selector: "address",
    sortable: true,
  },
  {
    name: "Total",
    selector: "price",
    sortable: true,
    cell: (row) => <div>{`${getCurrencySymbolFromCode()} ${row.price}`}</div>,
  },
  {
    name: "Order Status",
    selector: "order_status",
    sortable: true,
    cell: (row) => {
      const color = row.order_status === "ORDER_PLACED" ? "#846eae" : "#39ac4a";
      return <div style={{ color }}>{startCase(row.order_status)}</div>;
    },
  },
  {
    name: "Action",
    sortable: false,
    cell: (row) => {
      return (
        <IonButton fill="clear" size="small">
          <Link to={`/manage-orders/${row.order_num}`} className="text-link">
            Details
          </Link>
        </IonButton>
      );
    },
  },
];

class ManageOrders extends React.Component {
  getTableData = (page, pageLimit) => {
    return new Promise((resolve) => {
      orderServices
        .getAllOrders(page, pageLimit)
        .then((resp) => {
          return resolve({
            total: resp.data.total,
            data: resp.data.orders,
          });
        })
        .catch((err) => {
          // Handle data here.
          // Table component only expects resolved case currently
          console.error(err);
          return resolve({ total: 0, data: [] });
        });
    });
  };

  render() {
    return (
      <IonPage>
        <Navbar />
        <IonContent className="about-page">
          <div className="about-banner u-flex ion-align-items-center ion-justify-content-between">
            <div className="about-banner-left">
              <img src="./images/banner-bg-2.svg" alt="" />
            </div>
            <h1>ALL ORDERS</h1>
            <div className="about-banner-right">
              <img src="./images/banner-bg-2.svg" alt="" />
            </div>
          </div>
          <IonGrid fixed className="ion-padding">
            <IonTitle className="ion-text-center ion-margin">
              ORDER STATUS/HISTORY
            </IonTitle>
            <IonRow>
              <Table
                columns={columns}
                getRemoteData={this.getTableData}
                paginationServer
              />
            </IonRow>
          </IonGrid>
          <MenuSuggestions />
          <Footer />
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  // businessInfo: state.business.businessInfo,
  // isBusinessInfoLoading: state.business.isFetching,
});

export default connect(mapStateToProps)(ManageOrders);
