import { IonGrid, IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { connect } from "react-redux";
import { getOrder } from "../../client-submodule/redux/order/order.actions";
import {
  getBillTotal,
  getTax,
  getTotal,
} from "../../client-submodule/helpers/cart";
import { useLocation } from "react-router-dom";

const OrderSummary = (props) => {
  const { order = {}, restaurentSettings = {} } = props;
  const orderItems = order?.order_items || [];
  const subTotal = getBillTotal(order?.order_items);
  const location = useLocation();
  const tax = getTax(restaurentSettings?.tax_percent, subTotal);
  const total = getTotal(tax, subTotal);
  useEffect(() => {
    if (!orderItems.length && props.user) {
      props.getOrder(props.match.params.orderNum);
    }
  }, []);
  return (
    <IonPage>
      <Navbar />
      <IonContent className="cart-page">
        <IonGrid fixed className="u-full-height">
          {!props.isOrderLoading ? (
            <div className="cart-box">
              <table className="cart-table u-full-width">
                <tbody>
                  {orderItems.map((orderItem, idx) => (
                    <tr className="cart-item" key={idx}>
                      <td className="vegan-indicator">
                        <span
                          className={`typeindicator ${
                            orderItem.is_vegetarian ? "green" : "red"
                          }`}
                        >
                          <span className="dot" />
                        </span>
                      </td>
                      <td>{orderItem.name}</td>
                      <td style={{ width: "140px" }}>{orderItem.quantity}</td>
                      <td style={{ textAlign: "right" }}>
                        ${orderItem.price * orderItem.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="remarks-area"></div>

              <div className="bill-calculation">
                <hr />
                <div className="bill-item">
                  <div>Sub- Total</div>
                  <div>${total}</div>
                </div>
                {restaurentSettings?.tax_percent ? (
                  <div className="bill-item">
                    <div>Tax</div>
                    <div>${tax}</div>
                  </div>
                ) : null}
                <hr />
                <div className="bill-item final-amt">
                  <div>TO PAY</div>
                  <div>${total}</div>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </IonGrid>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  isOrderLoading: state.order.isFetching,
  order: state.order.order,
  restaurantId: state.business.restaurantId,
  user: state.auth.user,
  restaurentSettings: state.menu.restaurentSettings,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (orderId) => dispatch(getOrder(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
