import React from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonPage,
  IonCol,
  IonText,
  IonImg,
  IonButton,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import { connect } from "react-redux";
import { fetchBusinessInfo } from "../../client-submodule/redux/business/business.actions";
import FavouriteMenuItem from "../../components/FavouriteMenuItem";
import "./index.scss";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { getSlideOptions } from "../../utils/slide";
import Testimonals from "../../components/Testimonals";
import { addItemToCart } from "../../client-submodule/redux/cart/cart.actions";
import { cartItemMapSelector } from "../../client-submodule/redux/cart/cart.selectors";
import { getCurrencySymbolFromCode } from "../../client-submodule/helpers/cart";
import marketMenu from "../../assets/Susan's Samosas Market Menu.pdf";

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menusHighlited: [],
      menusDefault: this.props.menuItems,
      isFirstTime: true
    };
  }
  componentDidMount() {
    this.props.fetchBusinessInfo(process.env.REACT_APP_BUSINESS_ID);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.settings !== prevProps.settings) {
      if (this.state.isFirstTime || this.state.menusHighlited.length == 0) {
        this.setState({ isFirstTime: false })
        const { menuItems, cartItemMap } = this.props;
        const nonPickedItems = (menuItems?.menu_items ?? []).filter(
          (m) => !cartItemMap.has(m.id)
        );
        const highlightedMenuItems =
          nonPickedItems.length > 3
            ? nonPickedItems.slice(0, 3)
            : nonPickedItems.concat(menuItems?.menu_items ?? []);
        this.setState({
          menusHighlited: highlightedMenuItems.map((item) => ({ ...item, quantity: 1 }))
        });
      }
    }
  }


  updateMenu = (id, menu) => {
    this.props.addItemToCart(id, menu)
    // const objIndex = myArray.findIndex((obj => obj.id == 1));

    // this.state.menusHighlited[objIndex].name = "Laila"

    const newMenu = this.state.menusHighlited.map(p =>
      p.id === menu.id
        ? { ...p, quantity: menu.quantity }
        : p
    );
    this.setState({
      menusHighlited: newMenu,
    });
  };

  getHighlightedMenuItems = () => {
    const { menuItems, cartItemMap } = this.props;
    if (this.state.menusHighlited.length) {
      return this.state.menusHighlited;
    }
    const nonPickedItems = (menuItems?.menu_items ?? []).filter(
      (m) => !cartItemMap.has(m.id)
    );
    const highlightedItems =
      nonPickedItems.length > 3
        ? nonPickedItems.slice(0, 3)
        : nonPickedItems.concat(menuItems?.menu_items ?? []);
    return highlightedItems.map((item) => ({ ...item, quantity: 1 }))
  }

  render() {
    const { isMenuItemsLoading, testimonials, cartItemMap, settings } =
      this.props;
    
    const highlightedItems = this.getHighlightedMenuItems();

    const currencySymbol = getCurrencySymbolFromCode(settings.find(s =>
      s.setting_type === 'CURRENCY_CODE')?.setting_value);

    return (
      <IonPage id="main-content">
        <Navbar />
        <IonContent>
          <div className="home">
            <section className="bg-purple">
              <IonGrid className="favourite-items ion-no-padding" fixed>
                <IonRow className="ion-align-items-center">
                  <IonCol
                    sizeSm={5}
                    className="banner-left ion-padding-start ion-padding-end"
                  >
                    <div className="susan-title">
                      <h5 className="u-font-regular">Welcome to</h5>
                      <h1 className="u-title_lg">Susan's Samosas</h1>
                    </div>
                    <div className="btn-banner-wrap">
                      <IonButton
                        className="bg-green u-margin-bottom_1 u-border"
                        style={{ "--background": "#b6cc09", color: "#000" }}
                        onClick={() => this.props.history.push('/menu')}
                      >
                        Place An Order
                      </IonButton>
                      <IonButton
                        className="bg-green u-margin-bottom_1 u-border"
                        style={{ "--background": "#b6cc09", color: "#000" }}
                        href={marketMenu}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download Market Menu
                      </IonButton>
                      {
                        process.env.REACT_APP_ENABLE_CATERING === 'true' ?
                          <IonButton
                            color="light"
                            className="btn-catering u-border"
                            style={{ "--border-radius": 0 }}
                            onClick={() => this.props.history.push('/catering')}
                          >
                            Catering Services
                          </IonButton>
                          :
                          null
                      }
                    </div>
                  </IonCol>
                  <IonCol sizeSm={7} className="u-pos-rel ion-hide-sm-down">
                    <span className="purple-gradient" />
                    <span className="u-flex bg-green banner-text ion-align-items-center">
                      <IonImg
                        src="/images/icon_delivery_person.svg"
                        alt="Person"
                      />
                      <h6>
                        Hassle-free <br /> delivery!
                      </h6>
                      <IonImg
                        src="/images/icon_package.svg"
                        alt="Package"
                        className="u-ml__2"
                      />
                      <h6>Get it to go!</h6>
                    </span>
                    <IonImg
                      src="/images/home_banner.jpg"
                      alt="Samosas"
                      className="banner-image"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </section>
            {/* <div className="business-banner">
                  <img src={businessInfo?.business[1].banner_url} alt="" />
                </div> */}
            <IonGrid className="favourites-section ion-no-padding">
              <IonRow className="ion-no-padding">
                <IonCol sizeSm={2}>
                  <IonImg
                    src="/images/icon_hand.png"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </IonCol>
                <IonCol>
                  <div className="business-title">
                    <h3 className="u-font-regular ion-no-margin ion-margin-top">
                      Susan’s Scrumptious
                    </h3>
                    <div className="highlight">
                      <h1 className="u-font-size_1 ion-no-margin">
                        Star Samosas
                      </h1>
                    </div>
                  </div>
                  <p className="favourites-description ion-margin-vertical ion-text-center">
                    Susan’s one-of-a-kind, handmade South Sudanese samosas are packed with
                    flavor and ready to be devoured! Each samosa is made in small batches with
                    the finest local and organic ingredients. Selling thousands of
                    scrumptious samosas, Susan is quickly becoming a local favorite.
                  </p>
                </IonCol>
                <IonCol sizeSm={2}>
                  <IonImg src="/images/icon_hand.png" />
                </IonCol>
              </IonRow>
            </IonGrid>
            {isMenuItemsLoading ? (
              <div>Loading...</div>
            ) : (
              <IonGrid fixed className="ion-margin-bottom">
                <h1 className="u-title ion-margin ion-text-center">
                  Try one of our fan favorites
                </h1>
                <IonRow>
                  {highlightedItems.map((item) => (
                    <FavouriteMenuItem
                      key={item.id}
                      menuItem={item}
                      addToCart={this.updateMenu}
                      isAdded={cartItemMap.has(item.id)}
                      currencySymbol={currencySymbol}
                      min={0}
                      max={this.props.restaurentSettings?.max_order_quantity}
                    />
                  ))}
                </IonRow>
              </IonGrid>
            )}
            <Testimonals testimonals={testimonials} />
            <IonGrid fixed className="ion-padding-horizontal">
              <IonRow className="u-padding-horizontal">
                <IonCol sizeXs={12} sizeMd={7} className="learn-more-section_l">
                  <IonImg
                    src="/images/stars.svg"
                    className="star-icon banner-star_1"
                  />
                  <h1 className="ion-no-margin title-xl color-purple text1 u-pos-rel">
                    <span className="c-hyphen u-pos-rel">We also make </span>
                  </h1>
                  <h1 className="ion-no-margin text2 u-pos-rel color-purple">
                    {" "}
                    custom samosas to <br />
                    suit your taste buds!
                  </h1>
                </IonCol>
                <IonCol
                  sizeXs={12}
                  sizeMd={5}
                  className="u-flex ion-align-items-center learn-more-section_r"
                >
                  <div className="u-flex">
                    <div className="ion-text-center">
                      <IonImg src="/images/cover-person.svg" />
                      <IonButton
                        className="ion-margin"
                        style={{ "--background": "#b6cc09", color: "#000" }}
                      >
                        Learn More
                      </IonButton>
                    </div>
                    <IonImg
                      src="/images/stars.svg"
                      className="star-icon banner-star_2"
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="business-cover">
              <IonImg src="./images/susans-cover.png" alt="" />
            </div>
            <section
              className="bg-orange padding-lg ion-align-items-center"
              id="certificates"
            >
              <IonGrid fixed>
                <IonRow>
                  <IonCol sizeMd={6}>
                    <IonSlides pager={true} options={getSlideOptions(2)}>
                      {Array(3)
                        .fill(true)
                        .map((_, i) => (
                          <IonSlide key={i}>
                            <IonImg
                              src="/images/certificate.png"
                              alt="certificate"
                            />
                          </IonSlide>
                        ))}
                    </IonSlides>
                  </IonCol>
                  <IonCol sizeMd={6} className="ion-text-center">
                    <IonText color="dark" className="ion-padding">
                      <h3 className="ion-no-margin u-font-regular">
                        Establishing Roots
                      </h3>
                      <h1 className="ion-no-margin u-title">Certiﬁed</h1>
                      <h1 className="title-margin ion-no-margin u-title u-no-wrap">
                        {" "}
                        & Verified
                      </h1>
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </section>
          </div>
          <Footer />
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  isBusinessInfoLoading: state.business.isFetching,
  businessInfo: state.business.businessInfo,
  isMenuItemsLoading: state.menu.isFetching,
  menuItems: state.menu.menuItems,
  settings: state.business.settings,
  testimonials: state.business.testimonials,
  restaurentSettings: state.menu.restaurentSettings,
  ...cartItemMapSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusinessInfo: (id) => dispatch(fetchBusinessInfo(id)),
  addItemToCart: (restaurantId, menuItem) =>
    dispatch(addItemToCart(restaurantId, menuItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
