import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./index.scss";

const StripeCheckoutForm = ({ closeStripe, onPaymentCompletion }) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		const card = elements.getElement(CardElement);
		try {
			const result = await stripe.createToken(card);
			console.log('result', result)
			if (result.error) {
				onPaymentCompletion({ error: result.error });
			} else {
				onPaymentCompletion({ token: result.token.id, provider: 'STRIPE' });
			}
		} catch (error) {
			onPaymentCompletion({ error });
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<CardElement
				options={{
					style: {
						base: {
							fontSize: "18px",
							color: "#424770",
							margin: "10px",
							"::placeholder": {
								color: "#aab7c4",
							},
						},
						invalid: {
							color: "#9e2146",
						},
					},
				}}
			/>
			<button className="pay-button" type="submit" disabled={!stripe}>
				Pay
			</button>
			<button
				className="cancel-button"
				disabled={!stripe}
				onClick={closeStripe}
			>
				Cancel
			</button>
		</form>
	);
};

export default StripeCheckoutForm;
