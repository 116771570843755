import React from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";

const Input = ({
  position = "floating",
  label,
  value,
  onChange,
  errorMessage,
  showError,
  name,
  ...rest
}) => {
  return (
    <>
      <IonItem
        className="u-margin-bottom_1"
        style={{ "--background": "transparent" }}
      >
        <IonLabel position={position}>{label}</IonLabel>
        <IonInput
          value={value}
          onIonChange={(e) => onChange(e.target.value, name)}
          {...rest}
        />
      </IonItem>
      {showError && (
        <span className="u-input-error ion-padding-horizontal">
          {errorMessage || `${label} is required`}
        </span>
      )}
    </>
  );
};

export default Input;
