import { IonCard, IonCardContent, IonCol, IonGrid, IonImg, 
  IonRow, IonSlide, IonSlides, IonText, IonThumbnail, isPlatform } from "@ionic/react";
import React, { useRef, useEffect } from "react";
import { getSlideOptions } from "../../utils/slide";
import "./index.scss";


const Testimonals = ({ testimonals }) => {
  const slider = useRef(null);
  const isMobile = isPlatform("mobile");

  useEffect(() => {
    // return slider?.current?.startAutoplay();
  }, []);

  return (
    <div className="testimonials-container">
      <IonGrid fixed className="testimonals testimonal-title u-justify-center">
        <IonRow className="ion-align-items-center">
          <IonCol sizeSm={"1"}>
            <IonImg
              src="/images/stars.svg"
              className="star-icon u-margin-auto"
            />
          </IonCol>
          <IonCol>
            <IonText color="dark" className="ion-text-center">
              <h3 className="u-font-regular">Outstanding Service</h3>
              <h1 className=" u-no-margin u-title text1 u-pos-rel"><span className="c-hyphen u-pos-rel">What others</span></h1>
              <h1 className="title-margin u-title ion-no-margin text2 u-pos-rel"> are saying</h1>
            </IonText>
          </IonCol>
          <IonCol sizeSm={"1"}>
            <IonImg
              src="/images/stars.svg"
              className="star-icon u-margin-auto"
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <IonSlides pager={true} options={getSlideOptions(3)} ref={slider} className="slides">
            {testimonals.map(({ description, customer_name, customer_photo }, i) => (
              <IonSlide key={i}>
                <IonCard className="testimonal-col ion-padding">
                  <IonCardContent>
                    <IonText color="dark">
                      <p className="u-line-height-para">{description}</p>
                      <IonThumbnail className="u-margin-auto testimonal-thumbnail">
                        <figure>
                          <img src={customer_photo} alt={customer_name} />
                          <figcaption
                            itemProp="author"
                            className="ion-text-center "
                          >
                            {customer_name}
                          </figcaption>
                        </figure>
                      </IonThumbnail>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonSlide>
            ))}
          </IonSlides>          
          <IonImg
            src="/images/stars.svg"
            className="star-icon"
          />
        </IonRow>
      </IonGrid>
      {!isMobile && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative"
              }}
            >
              <div
                className="slide-nav-container prev"
                onClick={async () => {
                  return slider.current?.slidePrev();
                }}
              >
                <div className="slide-nav-btn prev-slide"></div>
              </div>
              <div
                className="slide-nav-container  next"
                onClick={async () => {
                  return slider.current?.slideNext();
                }}
              >
                <div className="slide-nav-btn next-slide"></div>
              </div>
            </div>
          )}
    </div>
      
  );
};

export default Testimonals;
