/**
 * Returns total from items with quantity and price
 * @param {Array<CartItem>} cartItems Array of cart items/menu items from response
 * @returns {string}
 */
export const getBillTotal = (cartItems = []) => {
  const total = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);
  return total.toFixed(2);
}

export const getTax = (tax_percent, total) => {
  if(tax_percent && total)
    return parseFloat(total * parseFloat(tax_percent) / 100).toFixed(2)
  else
    return 0
}

export const getTotal = (tax_amount, total_amount) => {
  return parseFloat(parseFloat(total_amount) + parseFloat(tax_amount)).toFixed(2)
}

export const getCurrencySymbolFromCode = (currencyCode = 'usd') => {  
  switch (currencyCode.toLowerCase()) {
    case 'usd':
      return '$';
    default:
      return '';
  }
}