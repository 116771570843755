import axios from "../../client/axios";

export const authServices = {
	fbLogin: async (accessToken) => {
		try {
			const response = await axios.post(`/auth/customers/facebook/login`, {
				accessToken,
			});
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	googleLogin: async (code, id_token) => {
		try {
			const response = await axios.post(`/auth/customers/google/login`, {
				code,
				id_token,
			});
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
};
