import OrderActionTypes from "./order.types";
import { orderServices } from "./order.services";

export const fetchOrderStart = () => ({
  type: OrderActionTypes.FETCH_ORDER_START,
});

export const fetchOrderSuccess = (orderInfo) => ({
  type: OrderActionTypes.FETCH_ORDER_SUCCESS,
  payload: orderInfo,
});

export const fetchOrderError = (error) => ({
  type: OrderActionTypes.FETCH_ORDER_FAILURE,
  payload: error,
});

export const fetchAllOrders = (page) => {
  return (dispatch) => {
    dispatch(fetchOrderStart());
    return orderServices.getAllOrders(page).then(
      (data) => {
        dispatch(fetchOrderSuccess(data.data));
      },
      (error) => {
        dispatch(fetchOrderError(error.message));
      }
    );
  };
};

export const getOrderStart = () => ({
  type: OrderActionTypes.GET_ORDER_START,
});

export const getOrderSuccess = (orderInfo) => ({
  type: OrderActionTypes.GET_ORDER_SUCCESS,
  payload: orderInfo,
});

export const getOrderError = (error) => ({
  type: OrderActionTypes.GET_ORDER_FAILURE,
  payload: error,
});

export const getOrder = (id) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    return orderServices.getOrder(id).then(
      (data) => {
        dispatch(getOrderSuccess(data.data));
      },
      (error) => {
        dispatch(getOrderError(error.message));
      }
    );
  };
};
