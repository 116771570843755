import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

export default class AppStorage {
	static setItem = async (key, value) => {
		await Storage.set({
			key,
			value: JSON.stringify(value),
		});
	};

	static getItem = async (key) => {
		const ret = await Storage.get({ key });
		return JSON.parse(ret.value);
	};

	static removeItem = async (key) => {
		await Storage.remove({ key });
	};

	static clear = async () => {
		await Storage.clear();
	};
}
