import React, { Component} from 'react';
import './Square.css';
import Square from './Square';
import squareicon from "../../assets/icons/square.png"

class SquarePayment extends Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentWillMount(){ 
    const that = this;
    let sqPaymentScript = document.createElement('script');
    sqPaymentScript.src = "https://web.squarecdn.com/v1/square.js";
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    sqPaymentScript.onload = ()=>{that.setState({
      loaded: true
    })};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }

  render() {
    return (
      this.state.loaded &&
        <Square
          client_id={this.props.client_id}
          amount={this.props.amount}
          paymentForm={ window.SqPaymentForm }
          onPaymentCompletion={this.props.onPaymentCompletion}
          customerLocation={this.props.customerLocation}
          customer={this.props.customer}
        />
    );
  }
}

export default SquarePayment;