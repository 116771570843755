const MenuActionTypes = {
    FETCH_MENU_ITEMS_START: "FETCH_MENU_ITEMS_START",
    FETCH_MENU_ITEMS_SUCCESS: "FETCH_MENU_ITEMS_SUCCESS",
    FETCH_MENU_ITEMS_FAILURE: "FETCH_MENU_ITEMS_FAILURE",
    ADD_ITEM_TO_CART_START: "ADD_ITEM_TO_CART_START",
    ADD_ITEM_TO_CART_SUCCESS: "ADD_ITEM_TO_CART_SUCCESS",
    ADD_ITEM_TO_CART_FAILURE: "ADD_ITEM_TO_CART_FAILURE",
    FETCH_MENUS: "FETCH_MENUS",
    FETCH_MENU_RESTAURENT_SUCCESS: "FETCH_MENU_RESTAURENT_SUCCESS",
    SET_SELECTED_MENU: "SET_SELECTED_MENU",
}

export default MenuActionTypes; 