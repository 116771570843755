import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Testimonals from "../../components/Testimonals";
import styles from "./styles.module.scss";
import QuantityInput from "../../components/QuantityInput";
import MenuSuggestions from "../../components/FavouriteMenuItem/MenuSuggestions";
import { addItemToCart } from "../../client-submodule/redux/cart/cart.actions";

const MenuDetail = ({ menuItems, match, cartItems, testimonials, restaurantId, restaurentSettings }) => {
  const menuItem =
    menuItems.find((mi) => mi.id.toString() === match.params.menuItemId) || {};
  const cartItem = cartItems.find(ci => ci.id === menuItem.id);
  const [quantity, setQuantity] = useState(cartItem?.quantity ?? 0);

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity ?? 0);
    addItemToCart(restaurantId, {
      ...cartItem,
      quantity,
    });
  }

  return (
    <IonPage>
      <Navbar />
      <IonContent>
      <IonGrid fixed className="ion-margin-vertical">
          <Link to="/" className="u-flex ion-align-items-center u-text-inherit">
            <IonImg
              src="/images/icon_arrow-left.svg"
              className={styles.iconLeft}
            />
            Back Home
          </Link>
          <IonRow className="ion-margin-bottom">
            <IonCol sizeSm={6}>
              <h1 className="ion-margin-vertical">{menuItem.name}</h1>
              <div className="u-flex ion-align-items-center ion-margin-vertical">
                {!menuItem.is_vegan && (
                  <span className="ion-margin-end">
                    <img src="/images/icon_veg.svg" alt="Leaf" className={styles.icon} />
                    <span>Vegetarian</span>
                  </span>
                )}
                {!menuItem.is_gluten_free && (
                  <span className="ion-margin-end">
                    <img src="/images/icon_organic.svg" alt="tick" className={styles.icon} />
                    <span>Organic</span>
                  </span>
                )}
                {!menuItem.is_gluten_free && (
                  <span>
                    <img src="/images/icon_hygiene.svg" alt="*" className={styles.icon} />
                    <span>100% hygiene</span>
                  </span>
                )}
              </div>
              <p className="ion-margin-vertical">{menuItem.description}</p>
              <div className="u-flex ion-align-items-center">
                <QuantityInput 
                minQuantity={menuItem.regular_min_quantity?menuItem.regular_min_quantity:restaurentSettings?.min_order_quantity}
                maxQuantity={menuItem.regular_max_quantity?menuItem.regular_max_quantity:restaurentSettings?.max_order_quantity}
                value={quantity} onChange={handleQuantityChange}/>
                <h2 className="ion-margin-horizontal">${`${menuItem.price}/per piece`}</h2>
              </div>
              <IonButton className="ion-margin-vertical u-border u-font-semi-bold" style={{ '--background': '#b6cc09' }}>
                Add to Cart
              </IonButton>
            </IonCol>
            <IonCol sizeSm={6}>
              <IonImg src={menuItem.photo_url} className={styles.pdtImage} alt={menuItem.name} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <Testimonals testimonals={testimonials} />
        <MenuSuggestions />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  menuItems: state.menu.menuItems.menu_items,
  cartItems: state.cart.cartItems,
  testimonials: state.business.testimonials,
  restaurantId: state.business.restaurantId,
  restaurentSettings: state.menu.restaurentSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addItemToCart: (restaurantId, menuItem) =>
    dispatch(addItemToCart(restaurantId, menuItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuDetail);
