import { IonButton, IonImg, IonInput } from '@ionic/react';
import React from 'react';
import { useToast } from "@agney/ir-toast";
import styles from "./styles.module.scss"

const QuantityInput = ({ value =1, onChange, maxQuantity, minQuantity }) => {
  const Toast = useToast();
  const handleChange = newVal => {
    if (minQuantity && newVal < minQuantity) {
      const toast = Toast.create({ message: `Please use Catering to order quantity above ${minQuantity}`, duration: 2000 });
      toast.present();
      onChange(minQuantity);
    }
    else if (maxQuantity && newVal <= maxQuantity) {
      onChange(newVal);
    } else {
      const toast = Toast.create({ message: `Please use Catering to order quantity above ${maxQuantity}`, duration: 2000 });
      toast.present();
      onChange(maxQuantity);
    }
  };
  return (
    <div className={`u-flex ${styles.container}`}>
      <div className={`u-flex ${styles.wrapper} ion-justify-between`}>
        <IonButton className="quantity-btn" fill="clear" onClick={() => handleChange(value > 0 ? value - 1 : 0)}>
          <IonImg src="/images/icon_minus.svg" />
        </IonButton>
        <IonInput
          value={value}
          readonly
          type='text'
          inputMode='numeric'
          className={`${styles.input} color-green ion-text-center ion-no-padding`}
          onIonBlur={e => handleChange(e.target.value)}
        />
        <IonButton className="color-green quantity-btn" fill="clear" onClick={() => handleChange(value + 1)}>
          <IonImg src="/images/icon_plus.svg" />
        </IonButton>
      </div>
    </div>
  )
}

export default QuantityInput;

