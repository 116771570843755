import React from "react";
import {
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonButton,
	IonTextarea,
	IonPage
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import "./index.scss";
import Navbar from "../../components/Navbar";

const Contact = () => {
	const { handleSubmit, control } = useForm();
	const onSubmit = (data) => {
		alert(JSON.stringify(data, null, 2));
	};

	return (
		<IonPage>
			<Navbar />
			<IonContent>
				<div className="catering-page">
					<div className="page-banner">
						<div className="page-title">Let us take care of you!</div>
					</div>
					<div className="heading-section">
						Call us or
						<br />
						<span className="section-title">Contact Us</span>
					</div>
					<div className="catering-form">
						<strong>Fill out the form & we will get in touch with you!</strong>
						<form className="catering-form-body" onSubmit={handleSubmit((data) => console.log(data))}>
							<IonItem>
								<IonLabel position="fixed">Name</IonLabel>
								<Controller
									render={({ onChange, onBlur, value }) => (
										<IonInput onIonChange={onChange} />
									)}
									control={control}
									name="name"
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="fixed">Email</IonLabel>
								<Controller
									render={({ onChange, onBlur, value }) => (
										<IonInput onIonChange={onChange} />
									)}
									control={control}
									name="email"
									rules={{
										required: true,
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											message: "invalid email address",
										},
									}}
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="fixed">Query</IonLabel>
								<Controller
									render={({ onChange, onBlur, value }) => (
										<IonTextarea onIonChange={onChange} />
									)}
									control={control}
									name="query"
								/>
							</IonItem>

							<IonButton type="submit">submit</IonButton>
						</form>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Contact;
