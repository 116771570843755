import BusinessActionTypes from "./business.types";

const INITIAL_STATE = {
    businessInfo: null,
    isFetching : true,
    errorMessage : '',
    restaurantId: process.env.REACT_APP_DEFAULT_RESTAURANT_ID,
    settings: [],
    testimonials: [],
}

const businessReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case BusinessActionTypes.FETCH_BUSINESS_START: 
            return {
                ...state,
                isFetching: true
            }
        case BusinessActionTypes.FETCH_BUSINESS_SUCCESS:
             return {
                 ...state,
                 isFetching: false,
                 businessInfo: action.payload
             }
        case BusinessActionTypes.FETCH_BUSINESS_FAILURE: 
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        case BusinessActionTypes.SET_BUSINESS_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            }
        case BusinessActionTypes.SET_TESTIMONALS:
            return {
                ...state,
                testimonials: action.payload,
            }
        case BusinessActionTypes.SET_RESTAURANT_ID:
            return {
                ...state,
                restaurantId: action.payload,
            }
        default:
            return state
    }
}

export default businessReducer;