import MenuActionTypes from "./menu.types";
import { menuServices } from "./menu.services";

export const fetchMenuItemsStart = () => ({
	type: MenuActionTypes.FETCH_MENU_ITEMS_START,
});

export const fetchMenuItemsSuccess = (businessInfo) => ({
	type: MenuActionTypes.FETCH_MENU_ITEMS_SUCCESS,
	payload: businessInfo,
});

export const fetchRestaurentSuccess = (businessInfo) => ({
	type: MenuActionTypes.FETCH_MENU_RESTAURENT_SUCCESS,
	payload: businessInfo,
});

export const fetchMenuItemsError = (error) => ({
	type: MenuActionTypes.FETCH_MENU_ITEMS_FAILURE,
	payload: error,
});

export const fetchMenuItems = () => {
	return (dispatch) => {
		dispatch(fetchMenuItemsStart());
		return menuServices.getMenuItems().then(
			(data) => {
				dispatch(fetchMenuItemsSuccess(data.data));
			},
			(error) => {
				dispatch(fetchMenuItemsError(error.message));
			}
		);
	};
};

export const fetchMenuItemsById = (id) => {
	return (dispatch) => {
		dispatch(fetchMenuItemsStart());
		return menuServices.getMenuItemsById(id).then(
			(data) => {
				dispatch(fetchMenuItemsSuccess(data.data));
			},
			(error) => {
				dispatch(fetchMenuItemsError(error.message));
			}
		);
	};
};

/**
 * @deprecated
 * This function is moved to business reducer
 */
export const fetchRestaurentId = (id) => {
	return (dispatch) => {
		dispatch(fetchMenuItemsStart());
		return menuServices.getRestaurentOrder(id).then(
			(data) => {
				dispatch(fetchRestaurentSuccess(data.data));
			},
			(error) => {
				dispatch(fetchMenuItemsError(error.message));
			}
		);
	};
};

export const fetchMenus = (restaurantId) => async dispatch => {
	try {
		dispatch(fetchMenuItemsStart());
		const menus = await menuServices.getAllMenus(restaurantId);
		dispatch({
			type: MenuActionTypes.FETCH_MENUS,
			payload: menus?.data?.menus,
		})
	} catch (error) {
		dispatch(fetchMenuItemsError(error.message));
	}
};

export const setSelectedMenuId = (menuId) => ({
	type: MenuActionTypes.SET_SELECTED_MENU,
	payload: menuId,
});
