import CartActionTypes from "./cart.types";

const INITIAL_STATE = {
	cateringCartItems: [],
	cartItems: [],
	isFetching: false,
    isAddingToCart: false,
	errorMessage: "",
	
};

const cartReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CartActionTypes.FETCH_CART_START:
			return {
				...state,
				isFetching: true,
			};
		case CartActionTypes.FETCH_CART_SUCCESS:
			return {
				...state,
				isFetching: false,
				cartItems: action.payload,
			};
		case CartActionTypes.FETCH_CATERING_CART_SUCCESS:
				return {
					...state,
					isFetching: false,
					cateringCartItems: action.payload,
				};
		case CartActionTypes.FETCH_CART_FAILURE:
			return {
				...state,
				isFetching: false,
				errorMessage: action.payload,
			};
		case CartActionTypes.ADD_TO_CART_START:
			return {
				...state,
				isAddingToCart: true,
			};
		case CartActionTypes.ADD_TO_CART_SUCCESS:
			return {
				...state,
				isAddingToCart: false,
				// cartItems: action.payload,
			};
		case CartActionTypes.ADD_TO_CART_FAILURE:
			return {
				...state,
				isAddingToCart: false,
				errorMessage: action.payload,
			};
		default:
			return state;
	}
};

export default cartReducer;
