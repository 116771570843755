import React from "react";
import { IonContent, IonGrid, IonPage, IonRow, IonText } from "@ionic/react";
import { connect } from "react-redux";
import { fetchMenus } from "../../client-submodule/redux/menu/menu.actions";
import { addItemToCart } from "../../client-submodule/redux/cart/cart.actions";
import MenuItemCard from "../../components/MenuItemCard";
import "./index.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ButtonGroup from "../../components/ButtonGroup";
import { menuItemMapSelector } from "../../client-submodule/redux/menu/menu.selectors";
import { cartItemMapSelector } from "../../client-submodule/redux/cart/cart.selectors";
import { getCurrencySymbolFromCode } from "../../client-submodule/helpers/cart";

class Menu extends React.Component {
  state = {
    selectedMenuGroup: null,
  };

  componentDidMount() {
    this.props.fetchMenus(this.props.restaurantId);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.menus.length && this.props.menus.length) {
      const defaultMenu = this.props.menus.find(menu => menu.is_default);
      this.setState({ selectedMenuGroup: defaultMenu ? defaultMenu.id : this.props.menus[0].id });
    }
  }

  render() {
    const {
      isMenuItemsLoading,
      menuItemMap,
      addItemToCart,
      cartItemMap,
      menus,
      settings,
      restaurantId,
    } = this.props;
    const menuItems = menuItemMap.get(this.state.selectedMenuGroup)
      ? Array.from(menuItemMap.get(this.state.selectedMenuGroup))
      : [];
    const menuOptions = menus.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    const currencySymbol = getCurrencySymbolFromCode(settings.find(s =>
      s.setting_type === 'CURRENCY_CODE')?.setting_value);

    const activeMenuItems =  menuItems.filter(item => item.is_active === true);

    return (
      <IonPage>
        <Navbar />
        <IonContent>
          <div className="menu-page">
            <div className="page-banner">
              <div className="page-title">Order Now</div>
            </div>
            <IonGrid fixed>
              <h1 className="section-title">
                <span className="line-one">Our</span>
                <br />
                <span className="second-line color-green">Menu</span>
              </h1>
              <ButtonGroup
                buttons={menuOptions}
                value={this.state.selectedMenuGroup}
                onChange={(val) => this.setState({ selectedMenuGroup: val })}
                className="ion-justify-content-around"
              />
              <div className="menu-section ion-padding-vertical">
                {isMenuItemsLoading ? (
                  <div>Loading...</div>
                ) : (
                  <IonGrid className="menu-items ion-padding-vertical">
                    <IonRow>
                      {activeMenuItems.length ? (
                        activeMenuItems.map((item, idx) => (
                          <MenuItemCard
                            key={idx}
                            menuItem={item}
                            addToCart={addItemToCart}
                            isAdded={cartItemMap.has(item.id)}
                            currencySymbol={currencySymbol}
                            restaurantId={restaurantId}
                            min={item.regular_min_quantity?item.regular_min_quantity:this.props.restaurentSettings?.min_order_quantity}
                           max={item.regular_max_quantity?item.regular_max_quantity:this.props.restaurentSettings?.max_order_quantity}
                          />
                        ))
                      )
                      :
                      <IonText className="ion-text-center u-full-width">
                        Sorry, Couldn't fetch menu. Please try again later
                      </IonText>
                      }
                    </IonRow>
                  </IonGrid>
                )}
              </div>
            </IonGrid>
          </div>
          <Footer />
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  ...menuItemMapSelector(state),
  ...cartItemMapSelector(state),
  restaurantId: state.business.restaurantId,
  menus: state.menu.menus,
  restaurentSettings: state.menu.restaurentSettings,
  settings: state.business.settings,
});

const mapDispatchToProps = (dispatch) => ({
  addItemToCart: (restaurantId, menuItem) =>
    dispatch(addItemToCart(restaurantId, menuItem)),
  fetchMenus: (restaurantId) => dispatch(fetchMenus(restaurantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
