import { createSelector } from 'reselect';

const menuItemsSelector = state => state.menu.menuItems.menu_items;

export const menuSelector = createSelector(
  (state) => state.menu.isFetching,
  menuItemsSelector,
  (isMenuItemsLoading, menuItems) => {
    return {
    menuItemMap: new Map(menuItems.map(m => [m.id, m])),
    isMenuItemsLoading
  }
});

/**
 * @param {Array<MenuItems>} menuItems menu items from store
 * @returns {Map<MenuId, Array<MenuItems>>}
 */
const getMenuMenuItemMap = menuItems => menuItems.reduce((menuMap, item) => {
  if (menuMap.has(item.menu_id)) {
    return menuMap.set(item.menu_id, menuMap.get(item.menu_id).concat(item))
  }
  return menuMap.set(item.menu_id, [item]);
}, new Map());

/**
 * Returns menu_id to menu_item map
 */
export const menuItemMapSelector = createSelector(
  (state) => state.menu.isFetching,
  menuItemsSelector,
  (isMenuItemsLoading, menuItems) => ({
    menuItemMap: getMenuMenuItemMap(menuItems),
    isMenuItemsLoading,
  })
);

export const menuItemFromMenusSelector = createSelector(
  state => state.menu.menus,
  (menus) => menus.map(menu => menu.menu_items).flat()
);
