import React,{useEffect, useState} from "react";
import { connect } from "react-redux";
import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonTextarea,
  IonPage,
  IonThumbnail,
  IonImg,
  IonGrid,
  IonRow,
  IonText,
  IonCol,
  IonDatetime,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import "./index.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { addItemToCateringCart } from "../../client-submodule/redux/cart/cart.actions";
import { useToast } from "@agney/ir-toast";

import MenuItemCard from "../../components/MenuItemCard";
import { addCatering } from "../../client-submodule/redux/catering/catering.actions";
import { cartItemMapSelector } from "../../client-submodule/redux/cart/cart.selectors";
import { getBillTotal, getCurrencySymbolFromCode, getTax, getTotal } from "../../client-submodule/helpers/cart";
import CartIcon from "../../components/Navbar/CartIcon";
import QuantityInput from "../../components/QuantityInput";

const Catering = (props) => {
  // const { handleSubmit, control } = useForm();
  const [isMenuAded, setAddMenu] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const [lineActive, setLineActive] = useState(false)
  const subTotal = getBillTotal(props.cartItems);
  const tax = getTax(props.restaurentSettings?.tax_percent, subTotal);
  const total = getTotal(tax, subTotal);

  const Toast = useToast();

  Array.prototype.sum = function (prop) {
    var total = 0
    for ( var i = 0, _len = this.length; i < _len; i++ ) {
        total += this[i][prop]
    }
    return total
}

  let cateringFormData = {};
  const handleSubmit =async (event) => {
    event.preventDefault();
    const { addCatering } = props;
    cateringFormData = { ...cateringFormData };
    cateringFormData.price = total;
    cateringFormData.customer_id = total;
    const data = await addCatering(cateringFormData);
    console.log("raseem",data)
  };

  const handleAddToCart = (qty,item) => {
    const { addItemToCateringCart } = props;
    addItemToCateringCart(qty,item);
  };

  const currencySymbol = getCurrencySymbolFromCode(
    props.settings.find((s) => s.setting_type === "CURRENCY_CODE")
      ?.setting_value
  );

  useEffect(()=>{
    if(props.cartItems && props.cartItems.length>0){
      setLineActive(true)
    }
  })
  const onChange = (e) => {
    switch (e.target.name) {
      case "fullname":
        // cateringFormData = { ...cateringFormData, name: e.target.value };
        break;
      case "email":
        // cateringFormData = { ...cateringFormData, email: e.target.value };
        break;
      case "typeofevent":
        cateringFormData = { ...cateringFormData, event_type: e.detail.value };
        break;
      case "orderdetails":
        cateringFormData = {
          ...cateringFormData,
          event_details: e.target.value,
        };
        break;
      case "date":
        cateringFormData = { ...cateringFormData, event_time: e.detail.value };
        break;
      default:
        break;
    }
  };
  return (
    <IonPage>
      <Navbar />
      <IonContent>
        <div className="catering-page">
          <div className="page-banner">
            <div className="page-title ion-text-center">
              Let us take care of you!
            </div>
          </div>
          <IonGrid
            fixed
            className="ion-padding-vertical u-flex ion-align-items-center"
          >
            <IonImg src="/images/icon_catering.svg" />
            <div>
              <div className="heading-section">
                Susan Samosa's
                <br />
                <span className="section-title">Catering Service</span>
              </div>
              <div className="catering-menu">
                <h5>Having a party or event?</h5>
                <p className="ion-no-margin">
                  Let us tingle your guest’s taste buds with amazing samosas.
                </p>
              </div>
            </div>
          </IonGrid>
          
          <div className="root-step">
            <div className={"circle-active"}
            onClick={()=>{setShowCart(false)
            setAddMenu(false)}}>1</div>
             <div className={!lineActive?"line":"line-active"}></div>
             <IonButton style={{ textTransform: "none",position:"absolute" }}
             className="catering-cart-icon"
             onClick={()=>setShowCart(!showCart)}>
                    <CartIcon count={props.cartItems && props.cartItems.sum('quantity')}  />
                  </IonButton>
             <div className={!isMenuAded?"circle":"circle-active"}
             onClick={()=>{
               if(props.cartItems.length>0)
               {
                setAddMenu(!isMenuAded)
                setShowCart(false)
               }
               else{
              const toast = Toast.create({ message: `Please add items to cart for catering request`, duration: 2000 });
              toast.present();
               }
               }}>2</div>
          </div>
          {showCart ?
<>
<div className="cart-box">
        <table className="cart-table u-full-width">
          <tbody>
            {props.cartItems.map((cartItem, idx) => (
              <tr className="cart-item" key={idx}>
                <td className="vegan-indicator">
                  <span
                    className={`typeindicator ${cartItem.is_vegetarian ? "green" : "red"
                      }`}
                  >
                    <span className="dot" />
                  </span>
                </td>
                <td>{cartItem.name}</td>
                <td style={{ width: "140px" }}>
                  <QuantityInput

                    minQuantity={cartItem.regular_min_quantity?cartItem.regular_min_quantity:props.restaurentSettings && props.restaurentSettings.min_order_quantity}
                    maxQuantity={cartItem.regular_max_quantity?cartItem.regular_max_quantity:props.restaurentSettings && props.restaurentSettings.max_order_quantity}
                    value={cartItem.quantity}

                    onChange={(quantity) =>
                      handleAddToCart(3, {
                        ...cartItem,
                        quantity,
                        
                      })
                    }
                  />
                </td>
                <td style={{ textAlign: 'right' }}>${cartItem.price * cartItem.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
       

        <div className="bill-calculation">
          <hr />
          <div className="bill-item">
            <div>Sub- Total</div>
            <div>${total}</div>
          </div>
          {
            props.restaurentSettings?.tax_percent ?
              <div className="bill-item">
                <div>Tax</div>
                <div>${tax}</div>
              </div>
              :
              null
          }
          <hr />
          <div className="bill-item final-amt">
            <div>TO PAY</div>
            <div>${total}</div>
          </div>
        </div>
        
        
      </div>
    </>
:
          !isMenuAded ?
          <IonGrid fixed className="menu-items ion-padding-vertical">
            <IonRow>
            
              {props.menuItems.length ? (
                props.menuItems.filter(item=> item.is_catering == true)?
                props.menuItems.filter(item=> item.is_catering == true).map((item, idx) => (
                  <MenuItemCard
                    key={idx}
                    menuItem={item}
                    addToCart={handleAddToCart}
                    isAdded={props.cartItemMap.has(item.id)}
                    currencySymbol={currencySymbol}
                    min={item.regular_min_quantity?item.regular_min_quantity:props.restaurentSettings && props.restaurentSettings.min_order_quantity}
                    max={item.regular_max_quantity?item.regular_max_quantity:props.restaurentSettings && props.restaurentSettings.max_order_quantity}
                  />
                ))
              :
              
              <IonText className="ion-text-center u-full-width">
                  Sorry, Couldn't fetch menu. Please try again later
                </IonText>
                
              ) : (
                <IonText className="ion-text-center u-full-width">
                  Sorry, Couldn't fetch menu. Please try again later
                </IonText>
              )}
              
            </IonRow>
            <IonRow className="go-to-cart-row">
            {
                props.menuItems?.filter(item=> item.is_catering == true) &&
              <IonButton
            className="bg-green u-no-margin add-cart u-font-semi-bold"
            style={{ "--background": "#b6cc09" }}
            // disabled={isAdded}
            onClick={() => {
              setShowCart(!showCart)
            }}
          >
         
            Go to Cart
          </IonButton>
}
            </IonRow>
          </IonGrid>
          :
          <div className="catering-form">
            <IonCol sizeMd={8} className="ion-text-center">
                <div className="ion-align-items-center">
                  <h5>Want Custom Samosas?</h5>
                  <h6>Have a custom samosa in mind for an upcoming event?</h6>
                  <h6>
                    No problem! Contact us and let us know what you would like
                  </h6>
                </div>
              </IonCol>
            <h5 className="u-color-orange">
              Fill out the form & we will get in touch with you!
            </h5>
            <form className="catering-form-body" onSubmit={handleSubmit}>
            <div className="u-flex u-margin-bottom_1 ion-text-left font-size-label">
                <IonLabel position="fixed">Total To Pay</IonLabel>
                <IonInput
                   
                  value={"$" +total}
                  disabled
                  className="u-border"
                />
                
              </div>
              <div className="u-flex u-margin-bottom_1 ion-text-left font-size-label">
                <IonLabel position="fixed">Name</IonLabel>
                <IonInput
                  onIonChange={onChange}
                  name="fullname"
                  className="u-border"
                />
                {/* <Controller
                  render={({ onChange, onBlur, value }) => (
                    
                  )}
                  control={control}
                  name="name"
                /> */}
              </div>
              <div className="u-flex u-margin-bottom_1 ion-text-left font-size-label">
                <IonLabel position="fixed">Email</IonLabel>
                <IonInput
                  onIonChange={onChange}
                  name="email"
                  className="u-border"
                  required
                />
                {/* <Controller
                  render={({ onChange, onBlur, value }) => (
                    
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  }}
                /> */}
              </div>
              <div className="u-flex u-margin-bottom_1 ion-text-left font-size-label">
                <IonLabel position="fixed">Type Of Event</IonLabel>
                {/* <IonInput
                  onIonChange={onChange}
                  name="typeofevent"
                  required
                  className="u-border"
                /> */}
                <IonSelect class="form-date"  placeholder="Select Event" name="typeofevent" onIonChange={onChange}>
              <IonSelectOption value="Weddings">Weddings</IonSelectOption>
              <IonSelectOption value="Funerals">Funerals</IonSelectOption>
              <IonSelectOption value="Bar Mitzvahs">Bar Mitzvahs</IonSelectOption>
              <IonSelectOption value="Birthdays">Birthdays</IonSelectOption>
              <IonSelectOption value="Sweet 16">Sweet 16</IonSelectOption>
              <IonSelectOption value="Quinceñera">Quinceñera</IonSelectOption>
              <IonSelectOption value="Others">Others</IonSelectOption>

            </IonSelect>
                {/* <Controller
                  render={({ onChange, onBlur, value }) => (
                    
                  )}
                  control={control}
                  name="eventType"
                  rules={{
                    required: true,
                  }}
                /> */}
              </div>
              <div className="u-flex u-margin-bottom_1 ion-text-left font-size-label">
                <IonLabel position="fixed">Date</IonLabel>
                {/* <IonInput
                  onIonChange={onChange}
                  name="date"
                  required
                  className="u-border"
                /> */}
                  <IonDatetime
                  class="form-date"
                displayFormat="DD-MM-YYYY"
                name="date"
                onIonChange={onChange}
              />
                {/* <Controller
                  render={({ onChange, onBlur, value }) => (
                    
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                  }}
                /> */}
              </div>
              <div className="u-flex u-margin-bottom_1 ion-text-left font-size-label">
                <IonLabel position="fixed">Anything else we should pass on?</IonLabel>
                <IonTextarea
                  onIonChange={onChange}
                  className="u-border ion-no-margin"
                  name="orderdetails"
                  required
                />
                {/* <Controller
                  render={({ onChange, onBlur, value }) => (
                    
                  )}
                  control={control}
                  name="orderDetails"
                /> */}
              </div>

              <IonButton
                type="submit"
                className="submit-button"
                style={{ "--background": "#43d3ff" }}
              >
                submit
              </IonButton>
            </form>
          </div>
}

        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  restaurantId: state.business.restaurantId,
  settings: state.business.settings,
  menuItems: state.menu.menuItems.menu_items,
  cartItems: state.cart.cateringCartItems,
  restaurentSettings: state.menu.restaurentSettings,
  cateringResponse: state,
  ...cartItemMapSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addItemToCateringCart: (restaurantId, menuItem) =>
    dispatch(addItemToCateringCart(restaurantId, menuItem)),
  addCatering: (cateringData) => dispatch(addCatering(cateringData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Catering);
