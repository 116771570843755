/**
 * An Error Class thrown if the API request has an error that needs to be propagated back to the caller
 */
 class APIError extends Error {
  status;

  reason;

  resp;

  /**
   * Creates an instance of APIError.
   * @param {string} reason Error message.
   * @param {object} message Custom error message to throw if any.
   * @param {number} status Status code
   */
  constructor(resp, message, status) {
    super(resp);
    this.resp = resp;
    this.status = status;
    this.message = message;
  }
}

export default APIError;
