import React,{useState} from "react";
import { IonButton, IonCol } from "@ionic/react";
import { useToast } from "@agney/ir-toast";
import { Link } from "react-router-dom";
import "./index.scss";
import QuantityInput from "../QuantityInput";


const FavouriteMenuItem = ({
  isAdded,
  addToCart,
  size = 4,
  colProps = {},
  menuItem,
  currencySymbol,
  restaurantId,
  min=1,
  max
}) => {
  const {
		id,
		name,
		price,
		description,
		photo_url,
    quantity
	} = menuItem;
  const Toast = useToast();
  const [cartCount, setCartCount] = useState(quantity)
  return (
    <IonCol className="ion-no-padding" sizeMd={size} {...colProps}>
      <div className="favourite-item-card ion-margin-horizontal">
        <Link to={`/menu/${id}`}>
          <div
            className="item-image"
            style={{ backgroundImage: `url(${photo_url})` }}
          >
          </div>
          <div className="item-details">
            <div className="item-name">{name}</div>
            <hr />
            <div className="item-description">{description}</div>
            <div className="u-font-regular item-price">{`${currencySymbol}${price}`}</div>
          </div>
        </Link>
        <div className="item-sale">
          {/* <div className="u-font-regular">{`${currencySymbol}${price}`}</div> */}
          <QuantityInput
                    maxQuantity={max}
                    minQuantity={min}
                    value={cartCount}
                    className="item-sale-controller"
                    onChange={(quantity) =>
                      // addItemToCart(3, {
                      //   ...cartItem,
                      //   quantity,
                      // })
                      setCartCount(quantity)
                    }
                  />
          <IonButton
            className="bg-green u-no-margin add-cart u-font-semi-bold"
            style={{ "--background": "#b6cc09" }}
            // disabled={isAdded}
            onClick={() => {
              addToCart(restaurantId, { ...menuItem, quantity: cartCount, menu_item_id: menuItem.id });
              const toast = Toast.create({ message: `${menuItem.name} added to cart.`, duration: 2000 });
							toast.present();
            }}
          >
            {/* {isAdded ? "Added to Cart" : "Add to Cart"} */}
            Add to Cart
          </IonButton>
        </div>
      </div>
    </IonCol>
  );
};

export default FavouriteMenuItem;
