import React, { useEffect, useState } from "react";
import { IonButton, IonTextarea } from "@ionic/react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addItemToCart,
  fetchCartItems,
} from "../../client-submodule/redux/cart/cart.actions";
import QuantityInput from "../../components/QuantityInput";
import { getBillTotal, getTax, getTotal } from "../../client-submodule/helpers/cart";

const CartContent = (props) => {
  const { cartItems = [], addItemToCart, isCartLoading, fromOrders, restaurentSettings } = props;
  const [remarkText, setRemarkText] = useState("");
  const subTotal = getBillTotal(cartItems);
  const tax = getTax(restaurentSettings?.tax_percent, subTotal);
  const total = getTotal(tax, subTotal);

  useEffect(() => {
    if (!cartItems.length && props.user) {
      console.log('RESTAURANT ID', props.restaurantId)
      props.fetchCartItems(props.restaurantId);
    }
  }, []);

  const renderCart = () =>
    cartItems.length ? (
      <div className="cart-box">
        <table className="cart-table u-full-width">
          <tbody>
            {cartItems.map((cartItem, idx) => (
              <tr className="cart-item" key={idx}>
                <td className="vegan-indicator">
                  <span
                    className={`typeindicator ${cartItem.is_vegetarian ? "green" : "red"
                      }`}
                  >
                    <span className="dot" />
                  </span>
                </td>
                <td>{cartItem.name}</td>
                <td style={{ width: "140px" }}>
                  <QuantityInput

                    minQuantity={cartItem.regular_min_quantity?cartItem.regular_min_quantity:restaurentSettings && restaurentSettings.min_order_quantity}
                    maxQuantity={cartItem.regular_max_quantity?cartItem.regular_max_quantity:restaurentSettings && restaurentSettings.max_order_quantity}
                    value={cartItem.quantity}

                    onChange={(quantity) =>
                      addItemToCart(3, {
                        ...cartItem,
                        quantity,
                      })
                    }
                  />
                </td>
                <td style={{ textAlign: 'right' }}>${cartItem.price * cartItem.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="remarks-area">
          <IonTextarea
            placeholder="Anything we should pass on?"
            value={remarkText}
            onIonChange={(e) => setRemarkText(e.target.value)}
          ></IonTextarea>
        </div>

        <div className="bill-calculation">
          <hr />
          <div className="bill-item">
            <div>Sub- Total</div>
            <div>${total}</div>
          </div>
          {
            restaurentSettings?.tax_percent ?
              <div className="bill-item">
                <div>Tax</div>
                <div>${tax}</div>
              </div>
              :
              null
          }
          <hr />
          <div className="bill-item final-amt">
            <div>TO PAY</div>
            <div>${total}</div>
          </div>
        </div>
        <Link to="/menu" className="u-text-inherit">
          <IonButton
            className="modify-order"
            color="dark"
            fill="clear"
            expand="block"
            size="large"
          >
            {"<"} Modify your order
          </IonButton>
        </Link>
        {!fromOrders && (
          <Link to="/checkout" className="u-text-inherit">
            <IonButton
              className="goto-cart u-border"
              size="large"
              expand="block"
              style={{ "--background": "#b6cc09" }}
            >
              CHECKOUT {">"}
            </IonButton>
          </Link>
        )}
      </div>
    ) : (
      <div className="cart-box u-full-height u-flex ion-align-items-center ion-justify-content-around">
        <div className="empty-msg ion-text-center">
          <h2 className="empty-title u-font-inherit">
            WHY IS YOUR BAG EMPTY?{" "}
          </h2>
          <h3 className="u-font-inherit">Add items with just one click</h3>
          <Link to="/menu" className="u-text-inherit">
            <IonButton
              className="goto-cart u-border"
              size="large"
              expand="block"
              style={{ "--background": "#b6cc09", "margin-top": "4rem" }}
            >
              GO TO MENU {">"}
            </IonButton>
          </Link>
        </div>
      </div>
    );

  return <>{isCartLoading ? <div>Loading...</div> : renderCart()}</>;
};

const mapStateToProps = (state) => ({
  isCartLoading: state.cart.isFetching,
  cartItems: state.cart.cartItems,
  restaurantId: state.business.restaurantId,
  user: state.auth.user,
  restaurentSettings: state.menu.restaurentSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCartItems: (restaurantId) => dispatch(fetchCartItems(restaurantId)),
  addItemToCart: (restaurantId, menuItem) =>
    dispatch(addItemToCart(restaurantId, menuItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartContent);
