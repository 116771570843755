import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { connect } from "react-redux";
import { ToastProvider, useToast } from "@agney/ir-toast";
import { fetchBusinessInfo } from "./client-submodule/redux/business/business.actions";

import Login from "./pages/Login";
import WithThemeWrapper from "./components/WithThemeWrapper";
import Sidebar from "./components/Sidebar";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/utility.scss";
import "./App.scss";
import Content from "./Content";

class App extends React.Component {
  componentDidMount() {
    // this.props.fetchBusinessInfo(1);
  }

  render() {
    const { isBusinessInfoLoading, businessInfo } = this.props;
    return (
      <IonApp>
        {/* {isBusinessInfoLoading ? (
					<div>Loading...</div>
				) : (
					<WithThemeWrapper themeClass={businessInfo.business[1].theme}> */}
        <ToastProvider>
        <IonReactRouter>
          <Sidebar />
          <IonRouterOutlet>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={Content} />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
        </ToastProvider>
        {/* </WithThemeWrapper> */}
      </IonApp>
    );
  }
}

const mapStateToProps = (state) => ({
  businessInfo: state.business.businessInfo,
  isBusinessInfoLoading: state.business.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusinessInfo: (id) => dispatch(fetchBusinessInfo(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
