import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const Table = (props) => {
  const [data, setData] = useState(props.data);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);

	const fetchData = async (page, limit = perPage) => {
		setLoading(true);
    if (props.getRemoteData) {
      props.getRemoteData(page, limit)
      .then(response => {
        setData(response.data);
        setTotalRows(response.total);
        setLoading(false);
      })
    }
	};

	const handlePageChange = page => {
		fetchData(page);
	};

	const handlePerRowsChange = (newLimit, page) => {
    setPerPage(newLimit);
		fetchData(page, newLimit);
	};

	useEffect(() => {
    if (props.getRemoteData) {
      fetchData(1, perPage);
    }
	}, []);

  return (
    <DataTable
			title={props.title}
			columns={props.columns}
			data={data}
			progressPending={loading}
			pagination
			paginationServer={props.paginationServer}
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
		/>
  );
};

export default Table;
