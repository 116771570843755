import React from "react";
import { IonButton } from "@ionic/react";
import styles from "./styles.module.scss"

const ButtonGroup = ({ buttons, onChange, value, className = "" }) => {
  if (!buttons.length) return null; // Error
  return (
    <div className={`u-flex ${className}`}>
      <span className={styles.wrapper}>
        {buttons.map((btn) => (
          <IonButton
            fill="clear"
            className="ion-no-margin"
            onClick={() => onChange(btn.value)}
            key={btn.value}
            color="dark"
            style={{
              'background': value === btn.value ? '#b6cc09' : '#ffffff',
            }}
          >
            {btn.label}
          </IonButton>
        ))}
      </span>
    </div>
  );
};

export default ButtonGroup;
