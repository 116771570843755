export const getSlideOptions = (maxSlideCount) => ({
  initialSlide: 1,
  loop: true,
  spaceBetween: 15,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    720: {
      slidesPerView: maxSlideCount,
    },
    540: {
      slidesPerView: maxSlideCount - 1,
    },
  },
})