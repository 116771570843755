import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router';

import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Cart from "./pages/Cart";
import Checkout from './pages/Checkout';
import Catering from "./pages/Catering";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Subscribe from "./pages/Subscribe";
import MenuDetail from './pages/MenuDetail';
import ManageOrders from './pages/ManageOrders';
import OrderSummary from './pages/OrderSummary';
import CovidTab from './pages/Covid19';
import Confirmation from './pages/Checkout/Confirmation';
import Location from './pages/Location';

import { fetchMenuItems,fetchRestaurentId } from './client-submodule/redux/menu/menu.actions';
import { fetchSettings, fetchTestimonials } from './client-submodule/redux/business/business.actions';

const Content = (props) => {
  useEffect(() => {
    if (!props?.menuItems?.length && !props.isFetching) {
      Promise.all([
        props.fetchMenuItems(),
        props.fetchRestaurentId(process.env.REACT_APP_DEFAULT_RESTAURANT_ID),
        props.fetchSettings(),
        props.fetchTestimonials(props.restaurantId),
      ]).catch(err => console.error(err))
    }
  }, []);

  return (
    <IonContent>
      <IonRouterOutlet>
        <Route exact path="/menu/:menuItemId" component={MenuDetail} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/catering" component={Catering} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/subscribe" component={Subscribe} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/checkout/confirmation/:orderNum" component={Confirmation} />
        <Route exact path="/manage-orders/:orderNum" component={OrderSummary} />
        <Route exact path="/our-story" component={About} />
        <Route exact path="/manage-orders" component={ManageOrders} />
        <Route exact path="/covid-19" component={CovidTab} />
        <Route exact path="/locations" component={Location} />
        <Route exact path="/" component={Home} />
        <Route render={() => <Redirect to="/" exact />} />
      </IonRouterOutlet>
    </IonContent>
  );
}

const mapStateToProps = ({ menu, business }) => ({
  menuItems: menu.menuItems.menu_items,
  isFetching: menu.isFetching,
  restaurantId: business.restaurantId,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMenuItems: () => dispatch(fetchMenuItems()),
  fetchSettings: () => dispatch(fetchSettings()),
  fetchTestimonials: (id) => dispatch(fetchTestimonials(id)),
  fetchRestaurentId: (id) => dispatch(fetchRestaurentId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Content)
