import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonThumbnail,
} from "@ionic/react";
import React from "react";
import Navbar from "../../components/Navbar";
import styles from "./styles.module.scss";
import Footer from "../../components/Footer";
import MenuSuggestions from "../../components/FavouriteMenuItem/MenuSuggestions";

const smPoints = [
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">We always</h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          {" "}
          wear masks
        </h1>
      </div>
    ),
  },
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">
          We wash hands often <br /> vigorously for at least{" "}
        </h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          20 secs with soap and hot water
        </h1>
      </div>
    ),
  },
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">We always</h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          wear gloves.
        </h1>
      </div>
    ),
  },
  {
    content: (
      <div className="about-description-title">
        <h1 className="ion-no-margin title-xl text1 u-pos-rel">We offer</h1>
        <h1 className="ion-no-margin text2 u-pos-rel color-purple u-ml__2">
          contactless delivery <br /> and takeout options.
        </h1>
      </div>
    ),
  },
];

const CovidTab = () => {
  return (
    <IonPage>
      <Navbar />
      <IonContent className="bg-white">
        <div className={`${styles.banner} business-cover`}>
          <IonGrid fixed className="ion-no-padding">
            <IonRow>
              <IonCol className="u-flex ion-align-items-center">
                <div>
                  <IonText className={styles.preTitle}>Locations</IonText>
                  <div className={styles.bannerTitle}>
                    <div className="u-flex ion-align-items-center">
                      <h1 className="ion-no-margin title-xl text1 u-pos-rel">
                        <span className="c-hyphen u-pos-rel">Susan</span>
                      </h1>
                      <div className="u-flex u-ml__2">
                        {Array(3)
                          .fill(true)
                          .map((_, i) => (
                            <IonThumbnail key={i} className={styles.samIcon}>
                              <IonImg src="/images/samosa.svg" alt="Samosa" />
                            </IonThumbnail>
                          ))}
                      </div>
                    </div>
                    <h1 className={`ion-no-margin text2 u-pos-rel ${styles.line2}`}>Samosas</h1>
                  </div>
                </div>

              </IonCol>
            </IonRow>
            <br/><br/><br/>            
            <IonRow className="ion-padding-horizontal">
              <IonCol                
                sizeXs={12}
                sizeSm={6}                
              >
                <h3>The Farmers Market Locations & Days</h3>
                <ol>
                  <li style={{ marginBottom: '10px' }}><a target="_blank" href="https://maps.app.goo.gl/FVCFm7eMvvL35Fzz9">Boulder Farmer Market - Saturdays</a></li>
                  <li style={{ marginBottom: '10px' }}>Longmont Farmer Market - Saturdays</li>
                  <li style={{ marginBottom: '10px' }}><a target="_blank" href="https://maps.app.goo.gl/8EQKZfjL5qdtt75B6">Nederland Farmer Market - Sundays</a></li>
                </ol>
              </IonCol>
              <IonCol                
                sizeXs={12}
                sizeSm={6}                
              >
                <h3>Retail Stores & Locations</h3>
                <ol>
                  <li style={{ marginBottom: '10px' }}><a target="_blank" href="https://maps.app.goo.gl/Nv3XC3Tnp4iSwcbr9">Nude Food Market in Boulder</a></li>
                  <li style={{ marginBottom: '10px' }}>Boulder county online farmer market In Longmont</li>
                  <li style={{ marginBottom: '10px' }}><a target="_blank" href="https://maps.app.goo.gl/i6y3zqH6Jue2Jo5B6">Ruby Market in Denver</a></li>
                  <li style={{ marginBottom: '10px' }}>Pinemelon in Denver</li>
                </ol>
              </IonCol>              
            </IonRow>
          </IonGrid>
        </div>
        <MenuSuggestions />
        {/* <IonGrid fixed>
          <IonRow className="ion-align-items-center">
            <IonCol sizeSm={9} sizeXs={12}>
              <div className="ion-text-left">
                <p className={`u-font-semi-bold ${styles.para}`}>
                  We are constantly monitoring reputable sources like the &nbsp;
                  <a href="https://www.cdc.gov/" target="_blank" rel="noopener noreferrer" className="u-link">CDC</a>
                  &nbsp; and <a href="https://www.who.int/" target="_blank" rel="noopener noreferrer" className="u-link">WHO</a> and update our protocols accordingly to ensure our
                  customer’s safety when preparing and packaging food.
                </p>
              </div>
            </IonCol>
            <IonCol sizeMd={1} className="ion-hide-sm-down" />
            <IonCol sizeMd={2} sizeSm={3} className="ion-hide-sm-down">
              <IonImg src="/images/covid_page_icon1.png" />
            </IonCol>
            
          </IonRow>
        </IonGrid> */}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default CovidTab;
