import axios from "../../client/axios";

export const placeOrder = async (data) => {
  try {
    const response = await axios.post('/order/place-order', data);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const orderServices = {
  placeOrder: async (data) => {
    try {
      const response = await axios.post("/v1/order/place-order", {
        data,
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  getAllOrders: async (page, page_limit) => {
    try {
      const response = await axios.get(`/order/user/`, {
        params: { 
          page: page || 1,
          page_limit,
        }
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  getOrder: async (id) => {
    try {
      const response = await axios.get(`/order/${id}`);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
};

export const makePayment = (orderNum, paymentProvider, token) =>
  axios.post(`/order/${orderNum}/payment`, { 
    payment_provider_id: paymentProvider,
    token,
  });
  
