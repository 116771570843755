import React, { useState } from 'react';
import { connect } from "react-redux";
import { useToast } from "@agney/ir-toast";
import { Stripe } from "@ionic-native/stripe";
import { IonPage, useIonViewDidEnter } from '@ionic/react';
import { useHistory } from 'react-router';

import { getBillTotal } from '../../client-submodule/helpers/cart';
import { addItemToCart, fetchCartItems } from '../../client-submodule/redux/cart/cart.actions';
import { cartItemMapSelector } from '../../client-submodule/redux/cart/cart.selectors';
import { makePayment, placeOrder } from '../../client-submodule/redux/order/order.services';
import CheckoutComponent from './CheckoutComponent';
import Navbar from '../../components/Navbar';
import { getCustomerLocations, selectCustomerLocation } from '../../client-submodule/redux/customer/customer.services';

const Checkout = (props) => {
  const [customerLocations, setCustomerLocations] = useState([]);
  const [orderNum, setOrderNum] = useState('');
  const Toast = useToast();
  const history = useHistory();    

  const presentToast = (message) => {
    const toast = Toast.create({ message, duration: 3000 });
    toast.present();
  }

  useIonViewDidEnter(() => {
    if (props.user) {
      getCustomerLocations()
        .then(resp => {
          const locations = resp.data.data.customerLocations;
          setCustomerLocations(locations);
          const selectedAddress = locations.find(l => l.is_selected);
          if (locations.length && selectedAddress) {
            props.fetchCartItems(props.restaurantId)
              .then((cart) => {
                console.log('cart', cart)
                if (cart.id) {
                  placeOrder({
                    cart_id: cart.id,
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    customer_location_id: selectedAddress.id,
                    is_catering: false,
                  })
                  .then(data => setOrderNum(data.data.order_num))
                  .catch(() => {
                    presentToast("Couldn't place your order. Please try again");
                  })
                }
              });
          }
        })
        .catch(() => {
          presentToast("Couldn't fetch saved locations.");
        })
    } else {
      presentToast('Please login to place order');
    }
  });

  const selectLocation = (locationId) => {
    selectCustomerLocation(locationId)
    .then(() => setCustomerLocations(existingLocation =>
      existingLocation.map(loc => {
        if (loc.id === locationId) {
          return { ...loc, is_selected: true };
        }
        return { ...loc, is_selected: false };
      })))
      .catch(() => {
        presentToast("Address update failed. Please try again");
      })
  };

  const payWithStripe = () => {
    Stripe.setPublishableKey(props?.restaurentSettings?.payment_provider.client_id);
    const cardDetails = {
      number: "4242424242424242",
      expMonth: 12,
      expYear: 2025,
      cvc: "220",
    };

    Stripe.createCardToken(cardDetails)
      .then((token) => {
        console.log(token);
      })
      .catch((error) => console.error(error));
  }

  const onPaymentCompletion = ({ token, error }) => {    
    if (token) {
      console.log("[PaymentMethod]", token);
      makePayment(orderNum, props?.restaurentSettings?.payment_provider.id, token)
        .then(() => history.push(`/checkout/confirmation/${orderNum}`))
        .catch(() => {
          presentToast("We couldn't complete payment. Please try again");
        })
    } else {
			console.log("[Error]", error);
    }
  }

  return (
    <IonPage>
      <Navbar />
      <CheckoutComponent
        {...props}
        payWithStripe={payWithStripe}
        customerLocations={customerLocations}
        selectLocation={selectLocation}
        onPaymentCompletion={onPaymentCompletion}
        handleAddAddress={newAddress =>
          setCustomerLocations(customerLocations.concat(newAddress))}
      />
    </IonPage>
  );
}

const mapStateToProps = (state) => ({
  billTotal: getBillTotal(state.cart.cartItems),  
  user: state.auth.user,
  menuItems: state.menu.menuItems,
  settings: state.business.settings,
  restaurantId: state.business.restaurantId,
  restaurentSettings: state.menu.restaurentSettings,
  ...cartItemMapSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCartItems: (restaurantId) => dispatch(fetchCartItems(restaurantId)),
  addItemToCart: (restaurantId, menuItem) =>
    dispatch(addItemToCart(restaurantId, menuItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

