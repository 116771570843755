import React from "react";
import {
	IonPage,
	IonContent,
	IonGrid,
} from "@ionic/react";
import "./index.scss";
import Navbar from "../../components/Navbar";
import CartContent from "./CartContent";


const Cart = () => (
	<IonPage>
		<Navbar />
		<IonContent className="cart-page">
			<IonGrid fixed className="u-full-height">
				<CartContent />
			</IonGrid>
		</IonContent>
	</IonPage>
);

export default Cart;
