import axios from "../../client/axios";

export const cateringRequests = {
  addCateringRequests: async (cateringData) => {
    try {
      const response = await axios.post(`/catering-requests`, {
        ...cateringData,
      });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
};
