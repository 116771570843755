import OrderActionTypes from "./order.types";

const INITIAL_STATE = {
  orders: [],
  order: {},
  isFetching: false,
  errorMessage: "",
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderActionTypes.FETCH_ORDER_START:
      return {
        ...state,
        isFetching: true,
      };
    case OrderActionTypes.FETCH_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orders: action.payload.orders,
        totalOrders: action.payload.total,
      };
    case OrderActionTypes.FETCH_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case OrderActionTypes.GET_ORDER_START:
      return {
        ...state,
        isFetching: true,
      };
    case OrderActionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        order: action.payload,
        totalOrders: action.payload.total,
      };
    case OrderActionTypes.GET_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
