import React, { Component } from "react";

const appId = process.env.REACT_APP_SQUARE_APPLICATION_ID;
const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

class SquarePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null,
      payments: null,
    };
  }
  async componentDidMount() {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    let card;
    try {
      card = await this.initializeCard(payments);
      console.log("Initialized Card successfully");
      this.setState({ card, payments });
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }
  }
  initializeCard = async (payments) => {
    const card = await payments.card();
    await card.attach("#card-container");
    return card;
  };

  tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  };

  verifyBuyer = async (payments, token) => {
    const verificationDetails = {
      amount: this.props.amount,
      billingContact: {
        givenName: this.props.customer?.given_name,
        familyName: this.props.customer?.family_name,
        email: this.props.customer?.email,
        addressLines: [this.props.customerLocation?.address_field1, this.props.customerLocation?.address_field1],
        city: this.props.customerLocation?.city,
        state: this.props.customerLocation?.state_province,
        countryCode: "US",
      },
      currencyCode: "USD",
      intent: "CHARGE",
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  };

  // status is either SUCCESS or FAILURE;
  displayPaymentResults = async (status) => {
    const statusContainer = document.getElementById("payment-status-container");
    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
    }

    statusContainer.style.visibility = "visible";
  };

  handlePaymentMethodSubmission = async (event) => {
    event.preventDefault();

    try {
      // disable the submit button as we await tokenization and make a payment request.
      const token = await this.tokenize(this.state.card);
      const verificationToken = await this.verifyBuyer(
        this.state.payments,
        token
      );
      console.log("verificationToken",verificationToken)
      this.props.onPaymentCompletion({
        token,
        provider: "SQUARE",
      });
      this.displayPaymentResults("SUCCESS");
      console.debug("Payment Success");
    } catch (e) {
      this.displayPaymentResults("FAILURE");
      console.error(e.message);
    }
  };
  render() {
    return (
      <form id="payment-form">
        <div id="card-container"></div>
        <button onClick={this.handlePaymentMethodSubmission} type="button">
          Pay ${this.props.amount}
        </button>
        <div id="payment-status-container"></div>
      </form>
    );
  }
}

export default SquarePaymentForm;
