import axios from "axios";
import Storage from "../helpers/storage";
import { kickUserOff } from "../redux/auth/auth.actions";
import store from "../redux/store";

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(async function (config) {
	const user = await Storage.getItem("user");
	if (user) {
		config.headers.Authorization = `JWT ${user.token}`;
	}
	return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
		console.info('interceptors', error)
    const {status} = error.response;
    if (status === 401) {
      store.dispatch((kickUserOff()));
    }
    return Promise.reject(error);
 }
);

export default axiosInstance;
