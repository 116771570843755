import axios from "../../client/axios";

export const menuServices = {
	getMenuItems: async () => {
		try {
			const response = await axios.get(`/menu-item/`);
			return response.data;
		} catch (e) {
			throw e;
		}
	},
	getMenuItemsById: async (id) => {
		try {
			const response = await axios.get(`/menu-item/${id}` );
			return response.data;
		} catch (e) {
			throw e;
		}
	},
	getAllMenus: async (restaurantId) => {
		const response = await axios.get(`/menus`, {
			params: {
				restaurant_id: restaurantId,
			}
		});
		return response.data;
	},
	getRestaurentOrder: async (id) => {
		try {
			const response = await axios.get(`/restaurants/${id}` );
			return response.data;
		} catch (e) {
			throw e;
		}
	},
};
