import React from "react";

const CartIcon = ({ count }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="32"
      viewBox="0 0 46 50"
    >
      <g>
        <g>
          <path
            fill="none"
            stroke="#000"
            strokeMiterlimit="20"
            d="M31.273 44.827v0H11.48a6.104 6.104 0 0 1-6.104-6.104V17.327v0h32v21.396a6.103 6.103 0 0 1-6.103 6.104z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000"
            strokeMiterlimit="20"
            d="M13.079 22.362v0S9.876 5.625 21.376 5.33c11.5-.294 8.475 17.032 8.475 17.032v0"
          />
        </g>
        {count > 0 && (
          <>
            <g>
              <g>
                <path
                  fill="#b6cc09"
                  d="M45.877 13.827c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12z"
                />
              </g>
            </g>
            <g>
              <g>
                <text
                  dominantBaseline="text-before-edge"
                  transform="translate(28 1)"
                >
                  <tspan fontFamily="'Avenir','Avenir-Roman'" fontSize="18">
                    {count}
                  </tspan>
                </text>
              </g>
            </g>
          </>
        )}
      </g>
    </svg>
  );
};

export default CartIcon;
